<template>
  <div class="row mt-3">
    <div class="col-12 col-md-3 mb-3">
      <NSelect
        v-model:value="FILTERS.hsCode"
        :options="COMMODITIES_OPTS"
        filterable
        clearable
        placeholder="Select commodities from the list"
        :disabled="inProgress"
        :loading="inProgress"
        :input-props="{ autocomplete: Math.random() }"
      />
    </div>
    <div class="col-12 col-md-3 mb-3">
      <NDatePicker
        v-model:value="FILTERS.years"
        type="yearrange"
        clearable
        :disabled="inProgress"
        :loading="inProgress"
      />
    </div>
    <div class="col-12 col-md-6 mb-3">
      <NSwitch
        v-model:value="FILTERS.monthlyView"
        :rail-style="railStyle"
        class="mb-3"
        style="margin-right: 10px"
        :disabled="inProgress"
        :loading="inProgress"
      >
        <template #checked> Monthly </template>
        <template #unchecked> Yearly </template>
      </NSwitch>
      <button
        class="btn btn-sm proxima-blue-bg-gradient bg-gradient-success text-white"
        @click="fetchImportAndExportData"
        :disabled="inProgress"
        :loading="inProgress"
      >
        Show Results
      </button>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-12">
      <h4>Import Data</h4>
      <TradeChart
        :trade-data="IMPORT_DATA"
        :years="FILTERS.years"
        :monthly-view="FILTERS.monthlyView"
        :loading="importDataLoading"
        :top-reporters="FILTERS.topImporters"
      />
    </div>
    <div class="col-12" v-if="IMPORT_DATA">
      <NSelect
        v-model:value="FILTERS.topImporters"
        :options="IMPORT_DATA_OPTS"
        multiple
        filterable
        clearable
        placeholder="Select importers from the list"
        :disabled="importDataLoading"
        :loading="importDataLoading"
        :input-props="{ autocomplete: Math.random() }"
      />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h4>Export Data</h4>
      <TradeChart
        :trade-data="EXPORT_DATA"
        :years="FILTERS.years"
        :monthly-view="FILTERS.monthlyView"
        :loading="exportDataLoading"
        :top-reporters="FILTERS.topExporters"
      />
    </div>
    <div class="col-12" v-if="EXPORT_DATA">
      <NSelect
        v-model:value="FILTERS.topExporters"
        :options="EXPORT_DATA_OPTS"
        multiple
        filterable
        clearable
        placeholder="Select exporters from the list"
        :disabled="exportDataLoading"
        :loading="exportDataLoading"
        :input-props="{ autocomplete: Math.random() }"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive, computed } from "vue";
import { NSelect, NDatePicker, NSwitch } from "naive-ui";
import { query } from "gql-query-builder";
import {
  COMMODITIES_OPTS,
  inProgress,
  singleFetchTradesParams,
} from "../../composables/tradeTool";
import { GQL_QUERIES } from "../../utils/APIs";
import { gqlRequest } from "../../composables/request";
import { BE_CORE_SERVICE_URL } from "../../utils/constants";
import TradeChart from "./Chart";

// TODO: this should not affect if multipke components is created
const thisYear = new Date().getFullYear();
const FILTERS = reactive({
  hsCode: null,
  years: [
    new Date().setFullYear(thisYear - 3),
    new Date().setFullYear(thisYear - 1),
  ],
  monthlyView: true,
  topImporters: null,
  topExporters: null,
});
const IMPORT_DATA = ref([]);
const IMPORT_DATA_OPTS = computed(() => {
  const opts = [
    ...new Map(
      (IMPORT_DATA.value || []).map((item) => [item["reporterId"], item])
    ).values(),
  ];
  return opts
    .filter((nth) => nth.reporterId !== "WORLD_TOTAL")
    .map((nth) => {
      return {
        label: nth.reporterName,
        value: nth.reporterId,
      };
    });
});
const EXPORT_DATA = ref([]);
const EXPORT_DATA_OPTS = computed(() => {
  const opts = [
    ...new Map(
      (EXPORT_DATA.value || []).map((item) => [item["reporterId"], item])
    ).values(),
  ];
  return opts
    .filter((nth) => nth.reporterId !== "WORLD_TOTAL")
    .map((nth) => {
      return {
        label: nth.reporterName,
        value: nth.reporterId,
      };
    });
});
const importDataLoading = ref(false);
const exportDataLoading = ref(false);

const yearRange = ref(null);

const railStyle = ({ focused, checked }) => {
  const style = {};
  if (!checked) {
    style.background = "#2080f0";
    if (focused) {
      style.boxShadow = "0 0 0 2px #2080f040";
    }
  }
  return style;
};

// will be run concurrently, so no need for AbortController
const fetchTrades = async (HSCode, year, tradeFlow) => {
  const gqlQuery = GQL_QUERIES.GET_WORLD_TRADE_DATA;
  const payload = query([
    singleFetchTradesParams(gqlQuery, HSCode, year, tradeFlow),
    // singleFetchTradesParams(gqlQuery, HSCode, year, "IMPORT"),
    // singleFetchTradesParams(gqlQuery, HSCode, year, "EXPORT"),
  ]);
  const response = await gqlRequest(payload, null, BE_CORE_SERVICE_URL);
  if (tradeFlow === "IMPORT")
    IMPORT_DATA.value = IMPORT_DATA.value.concat(
      response?.data?.[tradeFlow] || []
    );
  if (tradeFlow === "EXPORT")
    EXPORT_DATA.value = EXPORT_DATA.value.concat(
      response?.data?.[tradeFlow] || []
    );
};

const fetchAllTrades = async (tradeFlow) => {
  if (!(FILTERS.hsCode && FILTERS.years?.length > 0)) return;

  inProgress.value = true;
  const startYear = new Date(FILTERS.years[0]).getFullYear();
  const endYear = new Date(FILTERS.years[1]).getFullYear();

  // Note: could use promise
  const requests = [];
  let year = startYear;
  while (year <= endYear) {
    requests.push(fetchTrades(FILTERS.hsCode, year, tradeFlow));
    year++;
  }

  await Promise.all(requests);
  inProgress.value = false;
};

const fetchImportAndExportData = async () => {
  importDataLoading.value = true;
  FILTERS.topImporters = [];
  IMPORT_DATA.value = [];
  await fetchAllTrades("IMPORT");
  importDataLoading.value = false;

  exportDataLoading.value = true;
  FILTERS.topExporters = [];
  EXPORT_DATA.value = [];
  await fetchAllTrades("EXPORT");
  exportDataLoading.value = false;
};
</script>
