import { GQL_MUTATION } from "../utils/APIs"
import { mutation, query } from "gql-query-builder";
import { ref, reactive } from "vue";
import { gqlRequest } from "./request";

export const reqSignal = ref(new AbortController());
export const inProgress = ref(false);
export const PROFILE_TYPES = ["ALL", "ASSET", "COUNTRY", "COMPANY", "FILES"];
export const ASSET_PROFILE_TYPE = "ASSET";
export const COMPANY_PROFILE_TYPE = "COMPANY";
export const COUNTRY_PROFILE_TYPE = "COUNTRY";
export const SUBSCRIPTION_FILES_PROFILE_TYPE = "SUBSCRIPTION_FILES";

export const openNotification = async (id) => {
    const gqlQuery = GQL_MUTATION.OPEN_NOTIFICATION;
    reqSignal.value.abort();
    reqSignal.value = new AbortController();
    inProgress.value = true;

    const payload = mutation([
        {
            operation: gqlQuery.operation,
            variables: {
                id:{
                    value: id,
                    type: "Float",
                    required:true
                },
            },
            fields: ["message"]
        }
    ])
    const response = await gqlRequest(payload, reqSignal.value.signal);
    inProgress.value = false;
}
