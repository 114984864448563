<template>
  <div class="py-4 container-fluid" v-if="!profile || inProgress">
    <div class="row">
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <NSkeleton text :repeat="3" /> <NSkeleton text style="width: 60%" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid" v-else>
    <!-- <ProfilesMenu /> -->
    <div class="row">
      <div class="col-8 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h3 class="mb-4 proxima-blue-text">
                {{ profile.alias }}
              </h3>
              <div>
                <button class="btn bg-gradient-success btn-sm" @click="addNote">
                  {{
                    has_notes("ASSET", route)
                      ? $t("AssetProfile.viewNotesButton")
                      : $t("AssetProfile.addNoteButton")
                  }}
                </button>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-auto text-sm mb-3">
                <span class="text-muted pe-1"
                  >{{ $t("AssetProfile.typeHeader") }}:</span
                >
                <span class="proxima-blue-text font-weight-bold">{{
                  profile.type
                }}</span>
              </div>
              <div class="col-auto text-sm mb-3">
                <span class="text-muted pe-1"
                  >{{ $t("AssetProfile.operatorHeader") }}:</span
                >
                <span class="proxima-blue-text font-weight-bold">{{
                  profile.companyName
                }}</span>
              </div>
              <div class="col-auto text-sm mb-3">
                <span class="text-muted pe-1"
                  >{{ $t("AssetProfile.statusHeader") }}:</span
                >
                <span class="proxima-blue-text font-weight-bold">{{
                  profile.stage
                }}</span>
              </div>
            </div>
            <div class="row">
              <InfoText
                class="col-12 mb-3"
                v-if="profile.profile"
                :title="$t('AssetProfile.profileHeader')"
                :contents="profile.profile"
              />
              <InfoText
                class="col-12 mb-3"
                v-if="profile.projects"
                :title="$t('AssetProfile.projectsHeader')"
                :contents="profile.projects"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 mb-4">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-body">
                <div class="row mb-4" v-if="owners.length">
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("AssetProfile.ownershipHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-for="nth of owners || []"
                    :key="nth.companyName"
                    @click="goToCompanyProfile(nth.id)"
                    style="cursor: pointer"
                  >
                    {{ nth.companyName }}
                    {{ nth.percentage ? `(${nth.percentage}%)` : "" }}
                  </div>
                </div>
                <div
                  class="row mb-4"
                  v-if="
                    profile?.primaryCommodities ||
                    profile?.byProductCommodities ||
                    profile?.nonCommercialCommodities
                  "
                >
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("AssetProfile.marketsHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-if="profile?.primaryCommodities?.length"
                  >
                    {{ $t("AssetProfile.primaryHeader") }}:
                    {{ formatCommodities(profile?.primaryCommodities) }}
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-if="profile?.byProductCommodities?.length"
                  >
                    {{ $t("AssetProfile.byProductHeader") }}:
                    {{ formatCommodities(profile?.byProductCommodities) }}
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-if="profile?.nonCommercialCommodities?.length"
                  >
                    {{ $t("AssetProfile.nonCommercialHeader") }}:
                    {{ formatCommodities(profile?.nonCommercialCommodities) }}
                  </div>
                </div>
                <div class="row mb-4" v-if="products.length">
                  <div class="col-12">
                    <h5 class="proxima-blue-text">
                      {{ $t("AssetProfile.productsHeader") }}
                    </h5>
                  </div>
                  <div
                    class="col-12 text-sm"
                    v-for="nth of products || []"
                    :key="nth.name"
                  >
                    {{ nth.name }}
                    {{ nth.capacity ? `- ${nth.capacity} ${nth.unit != null ? nth.unit : ""}` : "" }}
                    {{
                      nth.grossContained === "Gross Weight"
                        ? ""
                        : nth.grossContained
                    }}
                    <span class="text-capi">
                      {{ nth.status ? `(${nth.status})` : "" }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4">
        <div class="card">
          <div class="card-body px-0 pb-1">
            <iframe
              id="gmap_canvas"
              width="100%"
              height="400"
              :src="`https://maps.google.com/maps?q=${
                profile.coordinates?.replace(' ', '') || profile.countryName
              }&t=k&z=${
                profile.coordinates ? 15 : 4
              }&ie=UTF8&iwloc=&output=embed`"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
            <div class="m-2 mx-3">
              {{
                $t("AssetProfile.locationFooter", {
                  country: profile.countryName,
                  pinAccuracy: formatPinAccuracy(profile),
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, computed, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ROUTES } from "../../routes/names";
import { NSkeleton } from "naive-ui";
import {
  MOUNTED_ASSET_PROFILE as profile,
  getAssetProfile,
  getAssetProfileDetails,
  inProgress,
  MOUNTED_ASSET_PROFILE_DETAILS 
} from "../../composables/profiles/asset";
import InfoText from "../../components/dumb/ProfileInfoText";
import {
  NOTES_WINDOW,
  SHOW_ALL_NOTES,
  has_notes,
} from "../../composables/notes/list";
import ProfilesMenu from "../../components/ProfilesMenu.vue";
import {
  getCompanyProfile,
  MOUNTED_COMPANY_PROFILE,
} from "../../composables/profiles/company";

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  const uuid = route.params?.id;
  const element = route.query?.element;
  // if (!uuid || !element) router.push({ name: ROUTES.PROXIMA_PROFILES.name });
  if (!profile.value){
    // if(!element){
    //   await getAssetProfileDetails(uuid)
    //   profile.value = MOUNTED_ASSET_PROFILE_DETAILS.value
    // }else{
      await getAssetProfile({ element, uuid });
    }
  // }

});
onBeforeUnmount(() => {
  profile.value = null;
});

const addNote = () => {
  NOTES_WINDOW.value = true;
  SHOW_ALL_NOTES.value = false;
};

const owners = computed(() => {
  return (profile.value?.owners || []).filter(
    (nth) => nth.companyName !== null
  );
});

const formatCommodities = (elements) => {
  return (elements || []).join(", ");
};

const products = computed(() => {
  return (profile.value?.products || []).filter((nth) => nth.name !== null);
});
// const goToCompanyProfile = async (id) => {
//   const element = route.query.element;
//   await getCompanyProfile({ element, uuid: null, id });
//   nextTick(() => {
//     if (MOUNTED_COMPANY_PROFILE.value?.uuid) {
//       router.push({
//         name: ROUTES.COMPANY_PROFILE.name,
//         params: { id: MOUNTED_COMPANY_PROFILE.value?.uuid },
//         query: { element },
//       });
//     } else window.$message.info("No profile found");
//   });
// };

const goToCompanyProfile = async (id) => {
  const element = route.query.element;
  const profileType = 'COMPANY'
  await getCompanyProfile({ element, uuid: null, id });
  nextTick(() => {
    if (MOUNTED_COMPANY_PROFILE.value?.uuid) {
      const uuid = MOUNTED_COMPANY_PROFILE.value?.uuid
      router.push({
        name: ROUTES.PROXIMA_PROFILES.name,
        // params: { id: MOUNTED_COMPANY_PROFILE.value?.uuid },
        query: {  _: Math.random(), profileType, uuid, element },
      });
    } else window.$message.info("No profile found");
  });
};

const formatPinAccuracy = (profile) => {
  return `${profile.type} / ${profile.nearestTown || "Nearest town"} / ${
    profile.province || "Province"
  }`;
};
</script>
