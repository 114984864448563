<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100 mb-12"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li
        v-for="(menu, key) in menuOptions"
        :key="key"
        class="nav-item proxima-secondary"
      >
        <SidenavItem
          :url="menu.routeName"
          :class="isActiveMenu(menu)"
          :disabled="menu.disabled"
        >
          <template v-slot:icon>
            <img :src="menu.icon" alt="homeIcon" style="width:20px"/>
          </template>
          <template v-slot:text>
            {{ menu.label }}
            <span
              v-if="menu.disabled"
              class="badge badge-info text-capitalize text-xxs p-1"
              >{{ $t("App.sidebarMenu.comingSoon") }}</span
            >
          </template>
        </SidenavItem>
      </li>
      <!-- <li class="nav-item proxima-secondary">
        <SidenavItem
          :url="ROUTES.DASHBOARD.name"
          :class="isActiveMenu(ROUTES.DASHBOARD.name)"
        >
          <template v-slot:text>
            <i class="ni ni-chart-bar-32 text-sm" style="margin-right: 23%"></i>
            {{ $t("App.sidebarMenu.dashboardPage") }}
          </template>
        </SidenavItem>
      </li>
      <li class="nav-item proxima-secondary">
        <SidenavItem
          :url="ROUTES.PROXIMA_PROFILES.name"
          :class="isActiveMenu(ROUTES.PROXIMA_PROFILES.name)"
        >
          <template v-slot:text>
            <i class="ni ni-pin-3 text-sm" style="margin-right: 20%"></i>
            {{ $t("App.sidebarMenu.proximaPage") }}</template
          >
        </SidenavItem>
      </li>
      <li class="nav-item">
        <SidenavItem
          :url="ROUTES.FILES.name"
          :class="isActiveMenu(ROUTES.FILES.name)"
        >
          <template v-slot:text>
            <i class="ni ni-folder-17 text-sm" style="margin-right: 10%"></i>
            {{ $t("App.sidebarMenu.filesPage") }}
          </template>
        </SidenavItem>
      </li> -->
      <!-- <li class="nav-item">
        <SidenavItem
          :url="ROUTES.STREAMS.name"
          :class="isActiveMenu(ROUTES.STREAMS.name)"
          :disabled="true"
        >
          <template v-slot:icon>
            <i class="ni ni-folder-17 text-dark text-sm"></i>
          </template>
          <template v-slot:text>
            {{ $t("App.sidebarMenu.streamsPage") }}
            <span class="badge badge-info text-capitalize text-xxs p-1">{{
              $t("App.sidebarMenu.comingSoon")
            }}</span>
          </template>
        </SidenavItem>
      </li> -->
      <!-- <li class="nav-item">
        <SidenavItem
          :url="ROUTES.SUBSCRIPTION.name"
          :class="isActiveMenu(ROUTES.SUBSCRIPTION.name)"
          :disabled="true"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-dark text-sm"></i>
          </template>
          <template v-slot:text>
            {{ $t("App.sidebarMenu.subscriptionsPage") }}
            <span class="badge badge-info text-capitalize text-xxs p-1">{{
              $t("App.sidebarMenu.comingSoon")
            }}</span>
          </template>
        </SidenavItem>
      </li> -->
    </ul>
  </div>
  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <SidenavCard />
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";
import SidenavItem from "./SidenavItem.vue";
import SidenavCard from "./SidenavCard.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const route = useRoute();

const menuOptions = [
  {
    label: t("App.sidebarMenu.dashboardPage"),
    routeName: ROUTES.DASHBOARD.name,
    icon: require("../../../public/images/sideMenuIcons/home_blue.svg"),
    disabled: false,
  },
  {
    label: t("App.sidebarMenu.marketData"),
    routeName: ROUTES.MARKET_DATA.name,
    icon: require("../../../public/images/sideMenuIcons/marketData_grey.svg"),
    disabled: true,
  },
  {
    label: t("App.sidebarMenu.proximaPage"),
    routeName: ROUTES.PROXIMA_PROFILES.name,
    icon: require("../../../public/images/sideMenuIcons/assetProfiles_blue.svg"),
    disabled: false,
  },
  {
    label: t("App.sidebarMenu.subscriptionsPage"),
    routeName: ROUTES.FILES.name,
    icon: require("../../../public/images/sideMenuIcons/dashboard_blue.svg"),
    disabled: false,
  },
  {
    label: t("App.sidebarMenu.costAnalysis"),
    routeName: ROUTES.COST_ANALYSIS.name,
    icon: require("../../../public/images/sideMenuIcons/costAnalysis_blue.svg"),
    disabled: false,
  },
  // {
  //   label: t("App.sidebarMenu.support"),
  //   routeName: ROUTES.SUPPORT.name,
  //   icon: require("../../../public/images/sideMenuIcons/support_blue.svg"),
  //   disabled: false,
  // },
];

const isActiveMenu = (menu) => {
  if(route.name === menu.routeName){
    return "active";
  }
  if(menu.disabled){
    return "disabled-menu";
  }
  // if()
  // return route.name === name.routeName ? "active" : "";
};
</script>

<style lang="scss" scoped>
.badge-info {
  // background-color: #F2AB26;
  background-color: #F2AB26;
  color: white;
}
.disabled-menu {
  // background-color: #F2F4F4 !important;
  color: grey !important; 
  &:hover{
    cursor: no-drop;
  }
}
// .active{
//   // color: #192E40 !important;
//   color: white !important;
//   // text-decoration: underline !important;
//   // text-decoration-thickness: 3px !important;
//   background-color: #192E40 !important;
// }
</style>
