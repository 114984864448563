<template>
  <div class="container p-0 mt-2 mb-3">
    <div class="row">
      <div class="col-md-12 mx-auto">
        <div class="contact-title">
          <p>Please let us know how we can assist you further:</p>
        </div>
        <div>
          <div class="row">
            <div class="col-12">
              <h6>Your Details:</h6>
            </div>
            <div class="col-12">
              <table style="width: 100%">
                <tr>
                  <td>
                    <span>Name: </span>
                  </td>
                  <td>
                    <span>{{ SUBSCRIPTION_INFO.fullname }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Email Address:</span>
                  </td>
                  <td>
                    <span>{{ props.userEmail }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-12 mt-2">
              <div class="form-group">
                <NInputGroup>
                  <NSelect
                    v-model:value="input.contactNumberPrefix"
                    :options="mobileList"
                    filterable
                    placeholder="Select Country"
                    class="proxima-number form-control custom-form"
                    @update:value="handleUpdateContactNumber"
                    style="width: 50%"
                  />
                  <input
                    v-model="input.contactNumber"
                    style="width: 50%"
                    type="text"
                    name="company"
                    class="form-control custom-form"
                    placeholder="Contact number"
                    required="required"
                    data-error="Contact number is required."
                  />
                </NInputGroup>
              </div>
            </div>

            <div class="col-sm-12">
              <div class="form-group">
                <textarea
                  id="form_message"
                  v-model="input.message"
                  name="message"
                  class="form-control message-form custom-form"
                  placeholder="*Your message"
                  rows="6"
                  required="required"
                  data-error="Please,leave us a message."
                />
                <div class="help-block with-errors" />
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12 btn-send">
              <button
                class="btn btn-default"
                :style="{
                  cursor: isFieldsReady ? 'pointer' : 'not-allowed',
                }"
                @click="sendToMarketing"
              >
                Send message
              </button>
            </div>
            <div class="col-sm-12">
              <p class="required pt-3">* These fields are required.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import {
  NSelect,
  NInputGroup,
  NCheckbox,
  NCheckboxGroup,
  NSpace,
  useMessage,
} from "naive-ui";
import { COUNTRIES_LIST } from "../../utils/generic.ts";
import { sendInquiry, RESPONSE } from "../../composables/marketSupport/inquiry";
import { SUBSCRIPTION_INFO } from "../../composables/subscription";
import { ROUTES } from "../../routes/names";
import { useRouter } from "vue-router";
const router = useRouter();

const { t } = useI18n();
const message = useMessage();
const props = defineProps(["elementProp", "userEmail"]);

const mobileList = COUNTRIES_LIST.map((nth) => {
  return {
    label: `(${nth.mobileCode}) ${nth.name}`,
    value: nth.code,
    mobile: nth.mobileCode,
  };
});

const countriesList = COUNTRIES_LIST.map((nth) => {
  return { label: nth.name, value: nth.code, mobile: nth.mobileCode };
});

const hCaptcha = ref();
const input = reactive({
  firstname: null,
  lastname: null,
  company: null,
  country: null,
  email: null,
  contactNumberPrefix: null,
  contactNumber: null,
  interests: null,
  sectors: null,
  message: null,
});

const handleUpdateContactNumber = (value, opt) => {
  input.contactNumberPrefix = `${opt.value} (${opt.mobile})`;
  input.country = countriesList
    .filter((country) => country.value === opt.value)
    .map((c) => c.label)[0];
};

const submitForCaptcha = () => {
  if (isFieldsReady.value) hCaptcha.value.execute();
};

const isFieldsReady = computed(() => {
  if (
    input.contactNumberPrefix !== null &&
    input.contactNumberPrefix !== "" &&
    input.contactNumber !== null &&
    input.contactNumber !== "" &&
    input.message !== null &&
    input.message !== ""
  )
    return true;
  return false;
});

const sendToMarketing = async () => {
  if (isFieldsReady.value) {
    await sendInquiry(input);

    let res = RESPONSE.value.message;
    if (res === "Ok") {
      router.push({
        name: ROUTES.MARKET_SUPPORT_SUCCESS.name,
      });
    } else {
      message.error("Request failed.");
    }
  }
};

const firstname = computed(() => {
  const str = SUBSCRIPTION_INFO.fullname;
  const whiteSpace = str.indexOf(" ");
  return str.substring(0, whiteSpace);
});
const lastname = computed(() => {
  const str = SUBSCRIPTION_INFO.fullname;
  const whiteSpace = str.indexOf(" ");
  return str.substring(whiteSpace + 1);
});

onMounted(() => {
  if (props) {
    input.interests = props.elementProp;
    input.email = props.userEmail;
    input.firstname = firstname.value;
    input.lastname = lastname.value;
  }
});
</script>

<style lang="scss" scoped>
:deep(.n-base-selection) {
  border: none !important;
  --n-border: none !important;
  --n-border-active: none !important;
  --n-border-focus: none !important;
  --n-border-hover: none !important;
  --n-box-shadow-active: none !important;
  --n-box-shadow-focus: none !important;
  --n-color: none !important;
  --n-color-active: none !important;
  --n-height: none !important;
  --n-padding-single: 0 26px 0 0 !important;
  --n-placeholder-color: #6c757d !important;
  font-family: "Gill Sans MT", sans-serif;
  padding: 8px 25px;
  top: 5px;
}

:deep(.n-select) {
  padding: 0 !important;
  height: 46px;
}

.custom-form {
  color: #020202;
  display: block;
  width: 100%;
  height: calc(2em + 1rem + 2px);
  // padding: 8px 25px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #f0f2f5 !important;
  background-clip: padding-box;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "Open Sans", sans-serif;
}

.custom-form::-ms-expand {
  background-color: transparent;
  border: 0;
}

.custom-form:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-form:focus {
  color: #495057;
  background-color: #d1d9e0;
  border-color: #80bdff;
  outline: 0;
  box-shadow: none;
}

.custom-form::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.custom-form::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.custom-form:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.custom-form::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.custom-form::placeholder {
  color: #6c757d;
  opacity: 1;
}
.message-form {
  background-color: #f0f2f5 !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.proxima-number {
  :deep(.n-base-selection-label) {
    // display: flex !important;
    padding-top: 0;
  }
}

:deep(.n-checkbox) {
  --n-color-checked: #192e40 !important;
  --n-border-checked: 1px solid #192e40 !important;
  --n-border-focus: 1px solid #192e40 !important;
}

.n-base-selection .n-base-selection-overlay {
  display: flex;
  align-items: center;
  white-space: nowrap;
  pointer-events: none;
  /* position: absolute; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // padding: var(--n-padding-single);
  // transition: color .3s var(--n-bezier);
}
</style>
