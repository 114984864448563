<template>
  <div>
    <div class="col-12 position-relative">
      <span
        class="custom-btn proxima-blue-button btn badge-sm text-white me-1 my-1 content-btn text-start p-1"
        @click="viewFiles(props.sortedFolders, CRITICAL_MATERIALS_NAME)"
        v-for="(value, key) of groupByFilesByType(
          props.sortedFolders
        )"
        :key="key"
      >
        <PopOver :text="key"  :isMonthly="false" :isCriticalMaterials="true" :data="value"/>
      </span>

      <span
        @click="
          viewFiles(
            props.sortedFolders,
            'MARKET_SUPPORT'
          )
        "
        class="proxima-blue-button custom-btn btn badge-sm text-white me-1 my-1 content-btn text-start p-1"
      >
        <PopOver :text="'MARKET_SUPPORT'"  :isMonthly="false" :isCriticalMaterials="false" :data="null"/>
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, computed } from 'vue';
import { SERIES_100, SERIES_200, SERIES_OTHERS, USER_FILES, SERIES_CRITICAL_MATERIALS, CRITICAL_MATERIALS_NAME } from "../../composables/files/list"
import { useI18n } from "vue-i18n";
import {
  NButton,
  NPopover,
} from "naive-ui";
import PopOver from '@/components/FilesButtons/PopOver.vue'

const { t } = useI18n();
const emit = defineEmits();
const props = defineProps(['sortedFolders']);
const overlap = ref(false);

const viewFiles = async (fileData, series) => {
  let data = {
    element: fileData.map(r => r.element)[0],
    files: fileData.map(r => r.files)[0],
    name:  fileData.map(r => r.name)[0],
    series: series
  }

  await emit('viewFiles', data);
};

const groupByFilesByType = (files) => {
  let _files = files.map(r => r.files)
  const type = {};
  for (const file of _files[0]) {
    if (!(file.fileType in type)) type[file.fileType] = [];
    type[file.fileType].push(file);
  }
  return type;
};

</script>


<style lang="scss" scope>
@import url("../../assets/styles/proxima-file-buttons.scss");
</style>

