import { getAuthToken } from "../utils";
import { ROUTES } from "./names";

export const isPrivateOnly = (to, from, next) => {
  const token = getAuthToken();
  if (!token) next({ name: ROUTES.INTERNAL_ERROR.name });
  next();
};

export const isPublicOnly = (to, from, next) => {
  const token = getAuthToken();
  if (token) next({ name: ROUTES.PROXIMA_PROFILES.name });
  next();
};

// reference: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
export const applyMetadata = (to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  else if (previousNearestWithMeta)
    document.title = previousNearestWithMeta.meta.title;

  const alias = "custom-metadata";
  Array.from(document.querySelectorAll(`[${alias}]`)).map((el) =>
    el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute(alias, "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
};
