export const fields = [
  "id",
  "uuid",
  "title",
  "product",
  "capacity",
  "unit",
  "type",
  "status",
  "announcedDate",
  "announcedStartDate",
  "actualStartDate",
  "endDate",
  "extendedEndDate",
  "contractPeriod",
  "dealProfile",
  {
    suppliersAndReceivers: [
      "companyId",
      "companyName",
      "partyType",
      "asset",
      "assetUUID",
    ],
  },
];
