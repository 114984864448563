<template>
  <div class="container">
    <div class="row">
      <div v-if="props.selectedElement !== 'LCS'" class="col-4">
        <n-popover trigger="hover">
          <template #trigger>
            <i
              @click="sendToMyEmail()"
              class="fa fa-envelope text-xs download-icon"
              aria-hidden="true"
            ></i>
          </template>
          <span>Send to email</span>
        </n-popover>
      </div>
      <div class="col-4">
        <n-popover trigger="hover">
          <template #trigger>
            <i
              @click="downloadFile()"
              class="fa fa-download text-xs download-icon"
              aria-hidden="true"
            ></i>
          </template>
          <span>Download</span>
        </n-popover>
      </div>
      <div v-if="props.selectedElement !== 'LCS'" class="col-4">
        <n-popover trigger="hover">
          <template #trigger>
            <i
              @click="viewInBrowser()"
              class="fa fa-globe text-xs download-icon"
              aria-hidden="true"
            ></i>
          </template>
          <span>View in browser</span>
        </n-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { NPopover, useMessage } from "naive-ui";
import { sendFileToEmail, EMAIL_RESPONSE } from "../../composables/files/list";

const showPopover = ref(false);
const message = useMessage();

const props = defineProps({
  selectedElement: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
  url: {
    type: String,
    default: false,
  },
  id: {
    type: Number,
    default: null,
  },
});
const downloadFile = () => {
  window.open(props.url);
};

const sendToMyEmail = async () => {
  await sendFileToEmail(props.id);

  let res = EMAIL_RESPONSE.value.message;
  if (res === "Ok") {
    message.success("Email has been sent!");
  } else {
    message.error("Sending email failed!");
  }
};

const viewInBrowser = () => {
  window.open(`https://docs.google.com/viewer?embedded=true&url=${props.url}`);
};
</script>

<style lang="css" scoped>
.download-icon {
  color: #192e40;
  cursor: pointer;
}
</style>
