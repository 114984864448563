<template>
  <a
    href="javascript:;"
    class="p-0 nav-link text-white"
    @click="invalidateAuthorization"
  >
    <i class="cursor-pointer fa fa-sign-out fa-lg"></i>
  </a>
</template>

<script setup>
import { nextTick } from "vue";
import { useRouter } from "vue-router";
import { logout } from "@/composables/auth";

const router = useRouter();

const invalidateAuthorization = () => {
  nextTick(async () => {
    await logout(router);
  });
};
</script>
