<template>
  <div>
    <div class="col-12 position-relative">
      <span
        class="custom-btn2 btn badge-sm text-white me-1 my-1 content-btn text-start p-1"
        :class="
          props.sortedFolders[0]?.files.length === 0
            ? 'proxima-disabled-button'
            : 'proxima-blue-button'
        "
        v-for="series in SERIES_COST"
        :key="series"
        @click="
          props.sortedFolders[0]?.files.length !== 0
            ? viewFiles(props.sortedFolders, series)
            : ''
        "
      >
        <span>
          <PopOver :text="series"/>
          <!-- <NPopover :overlap="overlap" trigger="hover" style="width: 400px">
            <template #trigger>
              <div class="container p-0">
                <div class="row">
                  <div class="col-1">
                    <img
                      :src="`images/${
                        series === 'MARKET_SUPPORT'
                          ? 'multiple-users-silhouette.png'
                          : 'xls_icon.png'
                      }`"
                      alt="icon"
                      class="image-fluid file-icon"
                    />
                  </div>
                  <div
                    class="col custom-btn-txt2"
                  >
                    {{ $t(`Files.seriesNames.${series}`) }}
                  </div>
                </div>
              </div>
            </template>
            <div>
              {{ $t(`Files.description.${series}`) }}
            </div>
          </NPopover> -->
        </span>
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from "vue";
import {
  SERIES_100,
  SERIES_200,
  SERIES_OTHERS,
  SERIES_MONTHLY,
  SERIES_COST
} from "../../composables/files/list";
import { useI18n } from "vue-i18n";
import { NButton, NPopover } from "naive-ui";
import PopOver from '@/components/FilesButtons/PopOver.vue'

const { t } = useI18n();
const emit = defineEmits();
const props = defineProps(["sortedFolders"]);
const overlap = ref(false);

const viewFiles = async (fileData, series) => {
  let data = {
    element: fileData.map((r) => r.element)[0],
    files: fileData.map((r) => r.files)[0],
    name: fileData.map((r) => r.name)[0],
    series: series,
  };
  await emit("viewFiles", data);
};
</script>

<style lang="scss" scope>
@import url("../../assets/styles/proxima-file-buttons.scss");
</style>
