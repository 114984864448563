import { ref } from "vue";
import jwtDecode from "jwt-decode";
import { apiRequest, isSuccess } from "./request";
import {
  setAuthToken,
  getAuthToken,
  removeAuthToken,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
} from "../utils";
import { AUTH } from "../utils/APIs";
import { ROUTES } from "../routes/names";

export const isSigningIn = ref(false);
export const LoginReqController = ref(new AbortController());
export const isValidatingToken = ref(false);
export const ValidateTokenReqController = ref(new AbortController());
export const isRefreshingToken = ref(false);
export const RefreshTokenReqController = ref(new AbortController());
export const isSigningOut = ref(false);
export const LogoutReqController = ref(new AbortController());
export const isResetingPassword = ref(false);
export const ResetPasswordReqController = ref(new AbortController());
export const isSigningUp = ref(false);
export const RegisterReqController = ref(new AbortController());

export const register = async (credentials) => {
  RegisterReqController.value.abort();
  RegisterReqController.value = new AbortController();
  isSigningUp.value = true;
  const response = await apiRequest(
    AUTH.REGISTER,
    "POST",
    {
      fullname: credentials.name,
      email: credentials.email,
      password: credentials.password,
      company: credentials.company,
      country: credentials.country,
      contactNumber: credentials.contactNumber,
      interests: credentials.interests || [],
      sectors: credentials.sectors || [],
      marketingPromotions: true,
    },
    RegisterReqController.value.signal,
    false
  );
  isSigningUp.value = false;

  if (response?.status === 409) window.$message.error("Email already exists");
  else if (isSuccess(response?.status)) return true;

  return false;
};

export const login = async (credentials) => {
  LoginReqController.value.abort();
  LoginReqController.value = new AbortController();
  isSigningIn.value = true;
  const response = await apiRequest(
    AUTH.LOGIN,
    "POST",
    {
      email: credentials.email,
      password: credentials.password,
    },
    LoginReqController.value.signal,
    false
  );
  isSigningIn.value = false;

  if (isSuccess(response?.status)) {
    const data = response.data;
    setAuthToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    return true;
  }

  return false;
};

export const validateToken = async (router) => {
  ValidateTokenReqController.value.abort();
  ValidateTokenReqController.value = new AbortController();
  isValidatingToken.value = true;
  const response = await apiRequest(
    AUTH.VALIDATE_TOKEN,
    "POST",
    {},
    ValidateTokenReqController.value.signal
  );
  isValidatingToken.value = false;

  if (response?.data?.status === 403) await renewTokenOrLogout(router);
};

const renewTokenOrLogout = async (router) => {
  const isAuthorized = await refreshToken();
  if (!isAuthorized) {
    await logout();
    router.push({ name: ROUTES.LOGIN.name });
  }
};

export const refreshToken = async () => {
  RefreshTokenReqController.value.abort();
  RefreshTokenReqController.value = new AbortController();
  isRefreshingToken.value = true;
  const response = await apiRequest(
    AUTH.REFRESH_TOKEN,
    "POST",
    { refreshToken: getRefreshToken() },
    RefreshTokenReqController.value.signal,
    false
  );
  isRefreshingToken.value = false;

  if (isSuccess(response?.status)) {
    // Note:
    // there may be some other request that will run along this that may fail,
    // better to find a way for this to finish first before running other requests
    const data = response.data;
    setAuthToken(data.accessToken);
    setRefreshToken(data.refreshToken);
    return true;
  }

  return false;
};

export const logout = async (router) => {
  LogoutReqController.value.abort();
  LogoutReqController.value = new AbortController();
  isSigningOut.value = true;
  await apiRequest(AUTH.LOGOUT, "POST", {}, LogoutReqController.value.signal);
  isSigningOut.value = false;

  removeAuthToken();
  removeRefreshToken();

  // router.push({ name: ROUTES.LOGIN.name });
  window.location.href = "/";
};

export const resetPassword = async (code, password) => {
  ResetPasswordReqController.value.abort();
  ResetPasswordReqController.value = new AbortController();
  isResetingPassword.value = true;
  const response = await apiRequest(
    AUTH.RESET_PASSWORD,
    "POST",
    {
      code,
      password,
    },
    ResetPasswordReqController.value.signal,
    false
  );
  isResetingPassword.value = false;

  if (isSuccess(response?.status)) return null;
  return response?.data?.message;
};
