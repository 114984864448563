import { ref } from "vue";
import { query } from "gql-query-builder";
import { gqlRequest } from "../request";
import { GQL_QUERIES } from "../../utils/APIs";

export const PUBLIC_ARTICLES = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const fields = [
  "id",
  "name",
  "publishDate",
  "authorName",
  "category",
  "countries",
  "companies",
  "elements",
  "products",
  "description",
  "feed",
  "opinion",
  "bannerImage",
  "externalUrl",
  "type",
  "authorAlias",
  {
    authors: ["description", "displayOnWebsite", {elements: ["element", "position"]}, "email", "name"]
  }
];

export const getPublicArticles = async () => {
  const gqlQuery = GQL_QUERIES.GET_PUBLIC_ARTICLES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            type: null,
            page: {
              current: 1,
              size: 1000,
            },
          },
          type: "GetPublicArticlesInput",
          required: true,
        },
      },
      fields: [
      {
        articles: fields
      }
      ]
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);

  PUBLIC_ARTICLES.value = response.data?.[gqlQuery.operation].articles || [];
  inProgress.value = false;

};
