import { computed, reactive, ref, watch } from "vue";
import { query } from "gql-query-builder";
import { sortBy } from "lodash";
import { gqlRequest } from "./request";
import { GQL_QUERIES } from "../utils/APIs";
import { LAST_SELECTED_ELEMENT_TO_VIEW } from "./generics";

export const SUBSCRIPTION_INFO = reactive({
  elements: [],
  bundles: [],
  subscriptionPackages: [],
  fullname: null,
  lastLogin: null,
});
export const NOTIFICATIONS = ref([]);
export const isBundle = ref(false);
export const isFetching = ref(false);
export const SUBSCRIPTION_ELEMENTS_OPTS = computed(() => {
  const bundle = SUBSCRIPTION_INFO.bundles.find(
    (nth) => nth.code === LAST_SELECTED_ELEMENT_TO_VIEW.value
  );
  let allBundledElements = [];
  for (const bundle of SUBSCRIPTION_INFO.bundles) {
    allBundledElements = allBundledElements.concat(
      bundle.bundledElements || []
    );
  }
  let opts = SUBSCRIPTION_INFO.elements
    .filter((nth) => !allBundledElements.includes(nth.code))
    .map((nth) => {
      return {
        label: nth.name,
        value: nth.code,
        type: "ELEMENT",
      };
    });
  const bundles = SUBSCRIPTION_INFO.bundles.map((nth) => {
    return {
      label: nth.name,
      value: nth.code,
      type: "PACKAGE",
    };
  });

  if (bundle) {
    opts = SUBSCRIPTION_INFO.elements
      .filter((nth) => bundle.bundledElements.includes(nth.code))
      .map((nth) => {
        return {
          label: nth.name,
          value: nth.code,
          parent: bundle.code,
          type: "ELEMENT",
        };
      });
    isBundle.value = true;
    return sortBy(opts, "label");
  } else {
    isBundle.value = false;
    return sortBy(opts.concat(bundles), "label");
  }
});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const getSubscriptionInfo = async () => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIPTION_DETAILS;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {},
      fields: [
        { elements: ["code", "name"] },
        { bundles: ["code", "name", "bundledElements"] },
        {
          subscriptionPackages: [
            "id",
            "packageName",
            "effectiveDate",
            { users: ["email", "fullname"] },
            "elements",
          ],
        },
        "fullname",
        "lastLogin",
      ],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);

  const data = response.data?.[gqlQuery.operation];
  SUBSCRIPTION_INFO.elements = data.elements || [];
  SUBSCRIPTION_INFO.bundles = data.bundles || [];
  SUBSCRIPTION_INFO.subscriptionPackages = data.subscriptionPackages || [];
  SUBSCRIPTION_INFO.fullname = data.fullname;
  SUBSCRIPTION_INFO.lastLogin = data.lastLogin;
  inProgress.value = false;
};
export const getNotifications = async () => {
  const gqlQuery = GQL_QUERIES.GET_NOTIFICATIONS;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {},
      fields: gqlQuery.fields,
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  NOTIFICATIONS.value = response.data?.[gqlQuery.operation];
  inProgress.value = false;

};

watch(SUBSCRIPTION_ELEMENTS_OPTS, (value) => {
  if (!LAST_SELECTED_ELEMENT_TO_VIEW.value) {
    LAST_SELECTED_ELEMENT_TO_VIEW.value = value.filter(
      (nth) => nth.type === "ELEMENT"
    )?.[0]?.value;
  }
});
