<template>
  <div
    class="px-3 text-sm text-wrap m-0 font-weight-bold cursor-pointer"
    style="line-height: 1.2"
    @click="showPreview"
  >
    {{ props.text || $t("Files.noDisplayName") }}
  </div>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: null,
  },
  url: {
    type: String,
    default: false,
  },
  type: {
    type: String,
    default: false,
  },
});

const showPreview = () => {
  if (props.url) {
    window.open(
      `https://docs.google.com/viewer?embedded=true&url=${props.url}`
    );
  }
};
</script>
