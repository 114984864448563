import { ref } from "vue";
import { mutation } from "gql-query-builder";
import { gqlRequest } from "../request";
import { GQL_MUTATION } from "../../utils/APIs";

export const USER_FILES = ref({});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());
export const RESPONSE = ref({});

export const sendInquiry = async (input) => {
    const gqlQuery = GQL_MUTATION.INQUIRE_TO_MARKETING;
    reqSignal.value.abort();
    reqSignal.value = new AbortController();
    inProgress.value = true;
    const payload = mutation([
        {
            operation: gqlQuery.operation,
            variables: {
                input: {
                    value: {
                        type: "INQUIRY",
                        firstname: input.firstname,
                        lastname: input.lastname,
                        company: input.company,
                        country: input.country,
                        email: input.email,
                        contactNumberPrefix: input.contactNumberPrefix,
                        contactNumber: input.contactNumber,
                        interests: input.interests || [],
                        sectors: input.sectors || [],
                        message: input.message,
                    },
                    type: gqlQuery.input,
                    required: true,
                }
            },
            fields: ["message"]
        }
    ]);
    const response = await gqlRequest(payload, reqSignal.value.signal);
    console.log("RESPONSE ===>>",response)
    inProgress.value = false;
    RESPONSE.value = response.data?.[gqlQuery.operation] || {};
}