import { ref } from "vue";
import { mutation, query } from "gql-query-builder";
import { gqlRequest } from "../request";
import { GQL_MUTATION, GQL_QUERIES } from "../../utils/APIs";

export const USER_FILES = ref({});
export const ALL_FILES = ref({});
export const STREAMS = ref([]);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());
export const EMAIL_RESPONSE = ref({});
export const MARKET_SERVICES2 = ref([]);
export const SERIES_100 = ["101", "102", "103"];
export const SERIES_200 = ["201", "202", "203"];
export const SERIES_MONTHLY = ["201", "MARKET_SUPPORT"];
export const SERIES_COST = ["DATA", "MARKET_SUPPORT"];
export const SERIES_CRITICAL_MATERIALS = ["CRITICAL_MATERIALS", "MARKET_SUPPORT"];
export const SERIES_OTHERS = ["DATA", "PROXIMA", "MARKET_SUPPORT"];
export const COST_SERVICES = ref([]);
export const MONTHLY_SERVICES = ref([]);
export const critMatFiles = ref([]);
export const criticalMaterials = ref([]);
export const criticalMaterialsData = ref([]);
export const CRITICAL_MATERIALS_NAME = 'CRITICAL_MATERIALS'
export const MARKET_SERVICE_FILE = 'marketService';
export const COST_SERVICE_FILE = 'costService';
export const DATA_SERIES_TYPE = 'DATA';


export const getUserFiles = async () => {
  const gqlQuery = GQL_QUERIES.GET_USER_FILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {},
      fields: [
        {
          files: [
            "element",
            "elementName",
            "coverPhotoUrl",
            {
              files: [
                "id",
                "displayName",
                "startDate",
                "endDate",
                "fileUrl",
                "fileType",
                "fileSeries",
                "coverPhotoUrl",
              ],
            },
          ],
        },
        {
          marketServices: [
            "id",
            "element",
            "name",
            "isPublic",
            "thumbnail",
            "serviceType"
          ]
        },
        "total",
        // {
        //   marketServices: [
        //     "element",
        //     "id",
        //     "isPublic",
        //     "name",
        //     "thumbnail"
        //   ]
        // }
      ],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);

  USER_FILES.value = response?.data?.[gqlQuery.operation] || {};
  MARKET_SERVICES2.value = response.data?.[gqlQuery.operation].marketServices
  COST_SERVICES.value = MARKET_SERVICES2.value?.filter(r => r.name.toLowerCase().includes("refined cost") || r.name.toLowerCase().includes("extractive cost") || r.name.toLowerCase().includes("cost")).map(r => r.element);
  MONTHLY_SERVICES.value = MARKET_SERVICES2.value?.filter(r => r.name.toLowerCase().includes("monthly")).map(r => r.element);
  criticalMaterials.value = MARKET_SERVICES2.value?.filter(r => r.element === CRITICAL_MATERIALS_NAME)
  inProgress.value = false;

};

export const getAllFiles = async () => {
  const gqlQuery = GQL_QUERIES.GET_ALL_FILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      fields: [
        "displayName",
        "elementBundles",
        "elements",
        "fileSeries",
        "fileType",
        "filename",
        "fileurl",
        "id",
        "isPublic",
        "key",
        "publishDateStart"
      ]
    }
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;
  ALL_FILES.value = response.data?.[gqlQuery.operation] || {};
}

export const getStreams = async () => {
  const gqlQuery = GQL_QUERIES.GET_STREAMS;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {},
      fields: ["displayName", "description", "uploadedDate", "fileUrl"],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;

  STREAMS.value = response.data?.[gqlQuery.operation] || {};
};

export const sendFileToEmail = async (id) => {
  const gqlQuery = GQL_MUTATION.DOWNLOAD_FILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = mutation([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            id,
          },
          type: gqlQuery.input,
          required: true,
        },
      },
      fields: ["message"],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;
  EMAIL_RESPONSE.value = response.data?.[gqlQuery.operation] || {};
};
