import { ref } from "vue";
import { query } from "gql-query-builder";
import { gqlRequest } from "../request";
import { GQL_QUERIES } from "../../utils/APIs";

export const NOTES_WINDOW = ref(false);
export const SHOW_ALL_NOTES = ref(false);
export const USER_NOTES = ref({});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const has_notes = (type, route) => {
  return (USER_NOTES.value?.notes || []).filter(
    (nth) =>
      nth.profileType === type &&
      nth.profileUUID === route.params.id &&
      nth.element === route.query.element
  ).length;
};

export const getUserNotes = async () => {
  const gqlQuery = GQL_QUERIES.GET_USER_NOTES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            page: {
              current: 1,
              size: 10000,
            },
          },
          type: gqlQuery.input,
          required: true,
        },
      },
      fields: [
        {
          notes: [
            "id",
            "title",
            "element",
            "profileUUID",
            "profileType",
            "taggedPersons",
            "note",
            "subscriptionPackage",
            "type",
            "created",
          ],
        },
        "total",
      ],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;

  USER_NOTES.value = response.data?.[gqlQuery.operation] || {};
};
