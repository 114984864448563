<template>
  <div class="row px-4">
    <div class="col-12 col-md-3 mb-3">
      <div class="col-s12 mb-3">
        <NSelect
          v-model:value="PROFILE_ID"
          :options="COMPANY_OPTS"
          filterable
          clearable
          :placeholder="t('ProximaList.companyProfiles.companyPlaceholder')"
          :loading="COMPANY_OPTS.length ? false : true"
          :input-props="{ autocomplete: Math.random() }"
          @update:value="
            (PROFILE_ID
              ? getCompanyProfile({ element: null, uuid: null, id: PROFILE_ID })
              : ''
            ), MOUNTED_PUBLIC_SUPPLY_DATA.value = null, clearRoute() 
          "
          :style="'width: 200px;'"
        />
      </div>

      <div class="col-12">
        <div class="row">
          <div class="col-12 mb-3">
            <h4>{{ MOUNTED_COMPANY_PROFILE?.alias || "" }}</h4>
            <SummaryCard
              v-if="PROFILE_ID"
              :title="$t('ProximaList.assetCard.title')"
              :profiles="companyAssets || []"
              :count="companyAssets.length"
              :error="$t('ProximaList.assetCard.notFound')"
              :hideDropdown="true"
              :mapComponent="false"
              :profileComponent="true"
            >
              <div
                class="text-center shadow icon icon-shape bg-gradient-primary rounded-circle"
              >
                <i class="text-lg opacity-10 ni ni-money-coins"></i>
              </div>
            </SummaryCard>

            <div
              class="overflow-auto"
              style="height: 500px"
              v-if="companyAssets"
            >
              <div
                class="col-12 text-sm"
                v-for="nth of companyAssets"
                :key="nth"
                :style="getCursorStyle(nth)"
              >
                <span @click="checkAccess(nth)">
                  {{
                    $t("CompanyProfile.relatedAsset", {
                      asset: nth.alias,
                      country: nth.countryName,
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9 mb-3">
      <div class="row">
        <InfoText
            class="col-12 mb-3"
            v-if="MOUNTED_COMPANY_PROFILE?.companyOverview"
            :title="$t('ProximaList.companyProfiles.companyOverviewTitle')"
            :contents="MOUNTED_COMPANY_PROFILE?.companyOverview"
          />
        <div v-if="PROFILE_ID" class="d-flex mb-3">
          <span
            class="text-muted"
            style="font-style: italic; margin-right: 10px; margin-top: 5px"
          >
            {{ t("ProximaList.companyProfiles.selectCommodityPlaceholder") }}
          </span>
          <NSelect
            v-model:value="COMMODITY_ID"
            :options="COMMODITY_OPTS"
            filterable
            clearable
            :placeholder="
              t('ProximaList.companyProfiles.commodityPlaceholder')
            "
            :loading="inProgress"
            :input-props="{ autocomplete: Math.random() }"
            :style="'width: 200px;'"
            @update:value="showCommodityOverview(COMMODITY_ID), getPublicSupplyData({company: PROFILE_ID, country: null, element: COMMODITY_ID, product: null})"
          />
        </div>
      </div>

      <div v-if="PROFILE_ID">
        <div v-if="hasData" class="col-12 mb-3">
          <NDataTable id="info-table" size="small" :columns="tableColumns" :data="patData" :loading="inProgress" striped resizable />
          <span v-if="hasData" class="text-muted" style="font-size: 13px; font-style: italic;">
            {{ t('ProximaList.companyProfiles.annualEstimatedLabel') }}
          </span>
        </div>
        <div class="col-12 mb-3">
          <InfoText
            class="col-12 mb-3"
            v-if="COMMODITY_OVERVIEW_DETAILS?.overview"
            title="Commodity Overview"
            :contents="COMMODITY_OVERVIEW_DETAILS?.overview"
          />
        </div>
      </div>
    </div>

    <NDrawer
      v-model:show="showAssetInfo"
      :width="400"
      :height="200"
      placement="right"
    >
      <NDrawerContent>
        <div
          class="row gap-y-2"
          v-if="PROFILE_TO_VIEW_IN_DRAWER.type === 'ASSET_PROFILE'"
        >
          <div class="col-12">
            <button
              class="btn btn-xs proxima-blue-bg-gradient bg-gradient-success text-white px-2"
              style="float: right"
              :disabled="inProgress"
              @click="
                goToAssetProfile({ assetUUID: PROFILE_TO_VIEW_IN_DRAWER?.uuid })
              "
            >
              Go to profile
            </button>
          </div>
          <div class="col-12">
            <h5>{{ PROFILE_TO_VIEW_IN_DRAWER.title }}</h5>
            <br />
          </div>
          <div class="col-12">
            <InfoText
              class="col-12 mb-3"
              v-if="PROFILE_TO_VIEW_IN_DRAWER?.profile"
              title="Profile"
              :contents="PROFILE_TO_VIEW_IN_DRAWER?.profile"
            />

            <InfoText
              class="col-12 mb-3"
              v-if="PROFILE_TO_VIEW_IN_DRAWER?.project"
              title="Project"
              :contents="PROFILE_TO_VIEW_IN_DRAWER?.project"
            />
          </div>
        </div>
        <div
          class="row gap-y-2"
          v-else-if="PROFILE_TO_VIEW_IN_DRAWER.type === 'COMPANY_PROFILE'"
        >
          <div class="col-12">
            <button
              class="btn btn-xs proxima-blue-bg-gradient bg-gradient-success text-white px-2"
              style="float: right"
              :disabled="inProgress"
              @click="goToCompanyProfile(PROFILE_TO_VIEW_IN_DRAWER?.uuid)"
            >
              Go to profile
            </button>
          </div>
          <div class="col-12">
            <h5>{{ PROFILE_TO_VIEW_IN_DRAWER.title }}</h5>
            <br />
          </div>
          <InfoText
            class="col-12 mb-3"
            v-if="PROFILE_TO_VIEW_IN_DRAWER?.overview"
            title="Overview"
            :contents="PROFILE_TO_VIEW_IN_DRAWER?.overview"
          />

          <InfoText
            class="col-12 mb-3"
            v-if="PROFILE_TO_VIEW_IN_DRAWER.profile"
            title="Profile"
            :contents="PROFILE_TO_VIEW_IN_DRAWER.profile"
          />
        </div>
      </NDrawerContent>
    </NDrawer>
  </div>
</template>

<script setup>
import { computed, h, nextTick, onMounted, ref, watch, defineProps  } from "vue";
import {
  NDataTable,
  NSelect,
  NDrawer,
  NDrawerContent,
  NDropdown,
} from "naive-ui";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";
import {
    getPublicSupplyData,
    MOUNTED_PUBLIC_SUPPLY_DATA
} from "../../composables/profiles/supplyData";
import {
  MOUNTED_ASSET_PROFILES,
  PROFILE_ID,
} from "../../composables/profiles/asset";
import { SUBSCRIPTION_INFO } from "../../composables/subscription";
import {
  ALL_COMPANIES_OPTS,
  MOUNTED_COMPANY_PROFILE,
  MOUNTED_COMPANIES,
  getCompanyProfile,
  getCompanies,
  inProgress,
} from "../../composables/profiles/company";
import {
  PRODUCT_GROUP_OPTS,
  MARKET_SERVICES_OPTS,
} from "../../utils/constants";
import {
  PROFILES_FILTERS,
  PUBLISHED_PROFILES,
  PUBLISHED_ALL_PROFILES,
  getPublishedProfiles,
  getAllPublishedProfiles,
  ASSETS_OPTS,
  COUNTRIES_OPTS,
  COMPANIES_OPTS,
  TYPES_OPTS,
  STAGES_OPTS,
  PRODUCT_OPTS,
} from "../../composables/profiles/list";
import TableHeader from "../Profiles/ProfileTable/TableHeader.vue";
import TableCell from "../Profiles/ProfileTable/TableCell";
import SummaryCard from "../../components/dumb/SummaryCard.vue";
import InfoText from "../../components/dumb/ProfileInfoText";
import { sortBy, uniqBy, groupBy, mapValues, sumBy, chain, map, find, omit } from "lodash";

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const props = defineProps(['companyProfileData']);

const PROFILE_TO_VIEW_DETAILS = ref(null);
const COMMODITY_ID = ref(null);
const COMMODITY_OVERVIEW_DETAILS = ref(null);
const elementOverviewData = ref([]);
const MOUNTED_ASSET_PROFILES2 = ref([]);
const MOUNTED_COMPANY_ASSET_PROFILES = ref(null);
const showAssetInfo = ref(false);
const PROFILE_TO_VIEW_IN_DRAWER = ref();

const COMPANY_OPTS = computed(() => {
  let opts =
    PUBLISHED_ALL_PROFILES.value?.companyProfiles?.map((r) => {
      return {
        label: r.alias,
        value: r.companyId,
      };
    }) || [];
  let sorted = sortBy(opts, "label");
  return uniqBy(sorted, "label");
});

const companyAssets = computed(() => {
  let assets = [];
  if (!COMMODITY_ID.value) {
    assets = PUBLISHED_ALL_PROFILES.value?.assetProfiles?.filter((r) =>
      r.owners?.map((r) => r.id).includes(PROFILE_ID.value)
    );
  } else {
    assets = PUBLISHED_ALL_PROFILES.value?.assetProfiles?.filter(
      (r) =>
        (r.byProductCommodities?.includes(COMMODITY_ID.value) || r.nonCommercialCommodities?.includes(COMMODITY_ID.value) ||  r.primaryCommodities?.includes(COMMODITY_ID.value)) &&
        r.owners?.map((r) => r.id).includes(PROFILE_ID.value)
    );
  }

  return sortBy(assets, "alias");
});

const getElementName = (el) => {
  return MARKET_SERVICES_OPTS.filter((e) => e.value === el).map(
    (r) => r.label
  )[0];
};

const COMMODITY_OPTS = computed(() => {
  let commodities =
    MOUNTED_COMPANY_PROFILE.value?.profileOverviews
      ?.filter((r) =>
        SUBSCRIPTION_INFO.elements?.map((nth) => nth.code).includes(r.element)
      )
      .map((r) => {
        return {
          label: getElementName(r.element) || r.element,
          value: r.element,
        };
      }) || [];
  return sortBy(commodities, "label");
});

const showCommodityOverview = async (com) => {
  COMMODITY_OVERVIEW_DETAILS.value = MOUNTED_COMPANY_PROFILE.value?.profileOverviews?.filter(
    (r) => r.element === com
  )[0];
  return COMMODITY_OVERVIEW_DETAILS.value;
};

const showDrawer = (nth) => {
  showAssetInfo.value = true;
  PROFILE_TO_VIEW_IN_DRAWER.value = {
    type: "ASSET_PROFILE",
    uuid: nth.uuid,
    title: nth.alias,
    profile: nth.profile,
    project: nth.projects,
  };
};

const goToAssetProfile = (nth) => {
  if (nth?.assetUUID) {
    router.push({
      name: ROUTES.ASSET_PROFILE.name,
      params: { id: nth?.assetUUID },
      query: { element: COMMODITY_ID.value },
    });
    PROFILE_ID.value = null;
    COMMODITY_ID.value = null;
  }
};

const cleanup = () => {
  PROFILE_ID.value = null;
  COMMODITY_ID.value = null;
  MOUNTED_COMPANY_PROFILE.value = null;
  COMMODITY_OVERVIEW_DETAILS.value = null;
};

const getCursorStyle = (nth) => {
  let subs = SUBSCRIPTION_INFO?.elements.map(r => r.code)
  const areAllElementsPresent = nth.byProductCommodities?.some(element => subs.includes(element)) || nth.primaryCommodities?.some(element => subs.includes(element));
  return {
    cursor: areAllElementsPresent ? 'pointer' : 'context-menu',
    'font-weight': areAllElementsPresent ? 'bold' : '',
  };
}

const checkAccess = (nth) => {
  let subs = SUBSCRIPTION_INFO?.elements.map(r => r.code)
  const areAllElementsPresent = nth.byProductCommodities?.some(element => subs.includes(element)) || nth.primaryCommodities?.some(element => subs.includes(element));
  if(areAllElementsPresent){
    showDrawer(nth)
  }
}

const publicSupplyData = computed(() => {
  return MOUNTED_PUBLIC_SUPPLY_DATA.value || [];
})

const patData = computed(() => {
    let groupedData = groupBy(publicSupplyData.value, 'productName')

    const summedData = map(omit(groupedData, [null]), (group, productName) => {
      const firstNonNullunits = find(group, 'units');
      return {
      productName: `${productName} (${firstNonNullunits ? firstNonNullunits.units : ''})`,
      yearlyData: chain(group)
        .map('yearlyData')
        .flatten()
        .groupBy('year')
        .map((values, year) => ({ year: parseInt(year), value:  parseFloat(
          sumBy(values, (entry) => parseFloat(entry.value))
          .toFixed(1)
        ).toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 }).replace(/,/g, ' ') }))
        .value()
      }
    });
  return summedData
})

const hasData = computed(() => {
    // Check if all yearlyData arrays are empty
    const allEmpty = patData.value.every(item => item.yearlyData.length === 0);

    // Example usage:
    if (allEmpty) {
        return false
    } else {
        return true
    }

});

const tableColumns = computed(() => {
  if (COMMODITY_ID.value) {
    let columns = [];
    // Product Name Column
    columns.push({
      align: "left",
      key: 'productName',
      fixed: 'left',
      width: 150,
      title(column) {
      return h(TableHeader, {
          text: 'Product',
      });
      },
      render: (row) => {
      // return row.productName;
        return h(TableCell, {
          text: row.productName
        })
      },
    });

    // Yearly Data Columns
    const uniqueYears = Array.from(new Set(patData.value.flatMap(entry => entry.yearlyData.map(data => data.year))));
    const yearCol = uniqueYears.sort();

    const currentDate = new Date();
    let currentYear = currentDate.getFullYear();

    for (let i = 0; i < yearCol.length; i++) {
      if (yearCol[i] >= 2018 && yearCol[i] <= currentYear) {
        columns.push({
          align: "center",
          key: yearCol[i],
          width: 100,
          title(column) {
          return h(TableHeader, {
              text: yearCol[i],
          });
          },
          render: (row) => {
          const yearlyData = row.yearlyData.find(data => data.year === yearCol[i]);
          return h(TableCell, {
            className: "rightAlign",
            text: yearlyData ? yearlyData.value : 0
          });
          },
        });
      }
    }
    return columns;
  } else {
    return [];
  }
});

const clearRoute = async () => {
  router.push({ path: route.path, query: { _: Math.random()} });
  await getCompanyProfile({ element: null, uuid: null, id: PROFILE_ID.value })
  COMMODITY_ID.value = null;
  COMMODITY_OVERVIEW_DETAILS.value = null;
  publicSupplyData.value = []

}

watch(PROFILE_ID, async () => {
  if (!PROFILE_ID.value) {
    cleanup();
  }

  MOUNTED_PUBLIC_SUPPLY_DATA.value = null;

  if(!route.query.uuid){
    // COMMODITY_ID.value = null;
    // MOUNTED_COMPANY_PROFILE.value = null;
    // COMMODITY_OVERVIEW_DETAILS.value = null;
    // publicSupplyData.value = []
  }
  
});

onMounted(async () => {
  if(props.companyProfileData){
    await getCompanyProfile({ element: null, uuid: props.companyProfileData.uuid, id: null })
    PROFILE_ID.value = MOUNTED_COMPANY_PROFILE.value?.companyId
    let element = props.companyProfileData.selectedElement ? props.companyProfileData.selectedElement : null
    if(element){
      COMMODITY_ID.value = element
      await showCommodityOverview(COMMODITY_ID.value);
      await getPublicSupplyData({company: PROFILE_ID.value, country: null, element: COMMODITY_ID.value, product: null});
    }
  }
  
  if(route.query.uuid || route.query.element){
    await getCompanyProfile({ element: route.query.element, uuid: route.query.uuid, id: null })
    PROFILE_ID.value = MOUNTED_COMPANY_PROFILE.value?.companyId
    if(route.query.element){
      COMMODITY_ID.value = route.query.element
      await showCommodityOverview(COMMODITY_ID.value);
      await getPublicSupplyData({company: PROFILE_ID.value, country: null, element: COMMODITY_ID.value, product: null});
    }
  }
  getCompanies();
  await getAllPublishedProfiles(null);
});
</script>

<style lang="scss" scoped>
// ROUNDED SCROLLBAR - START //
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: darkgrey;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgrey;
}

::-webkit-scrollbar-thumb:active {
  background: darkgrey;
}

// ROUNDED SCROLLBAR - END //
</style>
