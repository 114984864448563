<template>
  <div>
    <div class="col-12 position-relative">
      <span
        class="custom-btn btn badge-sm text-white me-1 my-1 content-btn text-start p-1"
        :class="
          props.sortedFolders[0]?.files.length === 0
            ? 'proxima-disabled-button'
            : 'proxima-blue-button'
        "
        v-for="series in SERIES_100"
        :key="series"
        @click="props.sortedFolders[0]?.files.length !== 0 ? viewFiles(props.sortedFolders, series) : ''"
      >
        <PopOver :text="series" :isMonthly="false" :isCriticalMaterials="false" :data="null"/>
      </span>
    </div>

    <div class="col-12 position-relative">
      <span
        class="custom-btn2 btn badge-sm text-white me-1 my-1 content-btn text-start p-1"
        :class="
          props.sortedFolders[0]?.files.length === 0
            ? 'proxima-disabled-button'
            : 'proxima-blue-button'
        "
        v-for="series in SERIES_200"
        :key="series"
        @click="props.sortedFolders[0]?.files.length !== 0 ? viewFiles(props.sortedFolders, series) : ''"
      >
        <span>
          <PopOver :text="series" :isMonthly="false" :isCriticalMaterials="false" :data="null"/>
        </span>
      </span>
    </div>

    <div class="col-12 position-relative">
      <span
        class="custom-btn btn badge-sm text-white me-1 my-1 content-btn text-start p-1"
        :class="
          props.sortedFolders[0]?.files.length === 0 && series !== 'MARKET_SUPPORT'
            ? 'proxima-disabled-button'
            : 'proxima-blue-button'
        "
        v-for="series in SERIES_OTHERS"
        :key="series"
        @click="
          props.sortedFolders[0]?.files.length === 0 && series !== 'MARKET_SUPPORT'
            ? ''
            : viewFiles(props.sortedFolders, series)
        "
      >
        <PopOver :text="series" :isMonthly="false" :isCriticalMaterials="false" :data="null"/>
      </span>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue';
import { SERIES_100, SERIES_200, SERIES_OTHERS } from "../../composables/files/list"
import { useI18n } from "vue-i18n";
import {
  NButton,
  NPopover,
} from "naive-ui";
import PopOver from '@/components/FilesButtons/PopOver.vue'

const { t } = useI18n();
const emit = defineEmits();
const props = defineProps(['sortedFolders']);
const overlap = ref(false);

const viewFiles = async (fileData, series) => {
  let data = {
    element: fileData.map(r => r.element)[0],
    files: fileData.map(r => r.files)[0],
    name:  fileData.map(r => r.name)[0],
    series: series
  }
  await emit('viewFiles', data);
};

</script>

<style lang="scss" scope>
@import url("../../assets/styles/proxima-file-buttons.scss");
</style>
