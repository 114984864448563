import { ref, computed, watch } from "vue";
import { query } from "gql-query-builder";
import { gqlRequest } from "../request";
import { GQL_QUERIES } from "../../utils/APIs";

export const MOUNTED_PUBLIC_SUPPLY_DATA = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const getPublicSupplyData = async ({company, country, element, product}) => {
    const gqlQuery = GQL_QUERIES.GET_PUBLIC_SUPPLY_DATA;
    reqSignal.value.abort();
    reqSignal.value = new AbortController();
    inProgress.value = true;
    const payload = query([
      {
        operation: gqlQuery.operation,
        variables: {
          input: {
            value: {
                company,
                country,
              element,
              product
            },
            type: gqlQuery.input,
            required: true,
          },
        },
        fields: gqlQuery.fields,
      },
    ]);
    const response = await gqlRequest(payload, reqSignal.value.signal);
    MOUNTED_PUBLIC_SUPPLY_DATA.value = response.data?.[gqlQuery.operation] || {};
    console.log("MOUNTED_PUBLIC_SUPPLY_DATA COUNTRY PROFILES ==>", MOUNTED_PUBLIC_SUPPLY_DATA.value)
    inProgress.value = false;
};