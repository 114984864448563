<template>
  <NPopselect
    v-model:value="LAST_SELECTED_ELEMENT_TO_VIEW"
    :options="SUBSCRIPTION_ELEMENTS_OPTS"
    size="medium"
    scrollable
    :style="`width: ${width}px`"
    :show="showMenu"
    @update:value="newElementSelected"
  >
  <div class="card" style="width: 120px;" ref="card" v-if="props.mapComponent">
      <div class="p-2 card-body">
        <div class="d-flex flex-row-reverse justify-content-between">
          <div
            class="avatar avatar-xs proxima-blue-bg-gradient border-radius-sm"
          >
            <span class="text-md text-bold text-white">{{
              getElementSymbol(selectedElement?.code)
            }}</span>
          </div>
          <div>
            <p
              class="mb-0 text-xs text-capitalize font-weight-bold proxima-blue-text"
            >
              {{ $t("ProximaList.elementCard.title") }}
            </p>
            <h6
              class="mb-0 font-weight-bolder proxima-blue-text"
              v-if="selectedElement"
            >
              {{ selectedElement?.name }}
            </h6>
            <span v-else class="text-sm text-muted">
              {{ $t("ProximaList.elementCard.notFound") }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="card" ref="card" v-else>
      <div class="p-3 card-body">
        <div class="d-flex flex-row-reverse justify-content-between">
          <div
            class="avatar avatar-md proxima-blue-bg-gradient border-radius-md"
          >
            <span class="text-lg text-bold text-white">{{
              getElementSymbol(selectedElement?.code)
            }}</span>
          </div>
          <div>
            <p
              class="mb-0 text-sm text-capitalize font-weight-bold proxima-blue-text"
            >
              {{ $t("ProximaList.elementCard.title") }}
            </p>
            <h5
              class="mb-0 font-weight-bolder proxima-blue-text"
              v-if="selectedElement"
            >
              {{ selectedElement?.name }}
            </h5>
            <span v-else class="text-sm text-muted">
              {{ $t("ProximaList.elementCard.notFound") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <template #action v-if="isBundle">
      <div style="width: 100%; cursor: pointer" @click="backToMainMenu">
        <NIcon size="20"><ArrowLeft /></NIcon>
        Back
      </div>
    </template>
  </NPopselect>
</template>

<script setup>
import { ref, onMounted, computed, nextTick, defineProps } from "vue";
import { NPopselect, NIcon } from "naive-ui";
import { useI18n } from "vue-i18n";
import { ArrowLeft } from "@vicons/tabler";
import { LAST_SELECTED_ELEMENT_TO_VIEW } from "../../composables/generics";
import {
  SUBSCRIPTION_INFO,
  SUBSCRIPTION_ELEMENTS_OPTS,
  isBundle,
} from "../../composables/subscription";
import { useRouter, useRoute } from "vue-router";
import { ROUTES } from "../../routes/names";
const props = defineProps(['mapComponent'])
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const card = ref(null);
const width = ref(null);
const showMenu = ref(undefined);
const selectedElement = computed(() => {
  if (route.params.id && route.query.element) {
    LAST_SELECTED_ELEMENT_TO_VIEW.value = route.query.element;
    const element = SUBSCRIPTION_INFO.elements.find(
      (nth) => nth.code === route.query.element
    );
    const bundle = SUBSCRIPTION_INFO.bundles.find(
      (nth) => nth.code === route.query.element
    );
    return element || bundle;
  } else {
    const element = SUBSCRIPTION_INFO.elements.find(
      (nth) => nth.code === LAST_SELECTED_ELEMENT_TO_VIEW.value
    );
    const bundle = SUBSCRIPTION_INFO.bundles.find(
      (nth) => nth.code === LAST_SELECTED_ELEMENT_TO_VIEW.value
    );
    return element || bundle;
  }
});

onMounted(() => {
  width.value = card.value?.offsetWidth || 200;
});

const backToMainMenu = () => {
  LAST_SELECTED_ELEMENT_TO_VIEW.value = null;
};

const newElementSelected = () => {
  nextTick(() => {
    if (isBundle.value) showMenu.value = true;
    else showMenu.value = undefined;
    if(route.query.element){
      router.push({ name: ROUTES.PROXIMA_PROFILES.name })
    }
    if(route.query.element){
      router.push({ name: ROUTES.PROXIMA_PROFILES.name })
    }
  });
};

const getElementSymbol = (code) => {
  if (code === "Graphite") return t("ProximaList.elementCard.GraphiteCode");
  else if (code === "SALT") return t("ProximaList.elementCard.SaltCode");
  return code;
};
</script>
