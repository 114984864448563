<template>
    <NPopover :overlap="overlap" trigger="hover" style="width: 400px">
        <template #trigger>
            <div class="container p-0">
                <div class="row">
                    <div class="col-1">
                        <img
                        :src="`images/${
                            SERIES_100.includes(props.text) || SERIES_200.includes(props.text) || isCriticalMaterials ? 'pdf_icon.png' :
                            props.text === 'PROXIMA'
                            ? 'location_icon.png'
                            : props.text === 'MARKET_SUPPORT'
                            ? 'multiple-users-silhouette.png'
                            : 'xls_icon.png'
                        }`"
                        alt="icon"
                        class="image-fluid file-icon"
                        />
                    </div>
                    <div v-if="SERIES_OTHERS.includes(props.text)" class="col custom-btn-txt2">
                        {{ $t(`Files.seriesNames.${props.text}`) }}
                    </div>
                    <div v-else-if="props.isMonthly" class="col custom-btn-txt2">
                        {{ $t(`Files.seriesNames.series4Title`) }}
                    </div>
                    <div v-else-if="props.isCriticalMaterials" class="col custom-btn-txt">
                        {{
                            props.data?.length || 0
                            ? `${props.data.length} ${props.text}`
                            : ""
                        }}
                    </div>
                    <div v-else class="col custom-btn-txt2">
                        {{ $t(`Files.seriesNames.${props.text}`) }}
                    </div>
                </div>
            </div>
        </template>
        <div v-if="props.isCriticalMaterials" class="col custom-btn-txt">
            {{
                props.data?.length || 0
                ? `${props.data.length} ${props.text}`
                : ""
            }}
        </div>
        <div v-else>
        {{ $t(`Files.description.${props.text}`) }}
        </div>
    </NPopover>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue';
import {
  NButton,
  NPopover,
} from "naive-ui";
import {
    SERIES_100,
    SERIES_200,
    SERIES_MONTHLY,
    SERIES_COST,
    SERIES_CRITICAL_MATERIALS,
    SERIES_OTHERS,
} from "../../composables/files/list";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const emit = defineEmits();
const props = defineProps(['text', 'isMonthly', 'isCriticalMaterials', 'data']);

</script>