import { ref, computed, watch } from "vue";
import { query } from "gql-query-builder";
import { gqlRequest } from "../request";
import { GQL_QUERIES } from "../../utils/APIs";
import { ROUTES } from "../../routes/names";

export const MOUNTED_COMPANY_PROFILE = ref(null);
export const MOUNTED_COMPANIES = ref(null);
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const fields = [
  "id",
  "uuid",
  "alias",
  "companyId",
  "companyName",
  "companyOverview",
  "profileOverview",
  { profileOverviews: ["element", "links", "overview"] },
  "coordinates",
  "headquarterCountry",
  "countryCode",
  "ownerType",
  "associatedCompanies",
  { stockExchanges: ["stockExchange", "tickerNames"] },
  { owners: ["id", "companyName", "percentage"] },
];

export const getCompanyProfile = async ({ element, uuid, id }) => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_COMPANY_PROFILE;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            element,
            uuid,
            id,
          },
          type: gqlQuery.input,
          required: true,
        },
      },
      fields
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  MOUNTED_COMPANY_PROFILE.value = response.data?.[gqlQuery.operation] || {};
  console.log("MOUNTED_COMPANY_PROFILE +++++>", MOUNTED_COMPANY_PROFILE.value)
  inProgress.value = false;


};

export const getCompanyProfiles = async ({id,country,commodity,keyword}) => {
  const gqlQuery = GQL_QUERIES.GET_COMPANY_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            company: id,
            country: country,
            commodity: commodity,
            keyword: keyword,
            page: {
              current: 1,
              size: 4000,
              sortColumn: null,
              sortOrder: null
            },
          },
          type: gqlQuery.input,
          required: true,
        },
      },
      fields: gqlQuery.fields
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);

  MOUNTED_COMPANY_PROFILE.value = response.data?.[gqlQuery.operation] || {};
  inProgress.value = false;

};

export const getCompanies = async (element) => {
  // console.log("GETCOMPANIES +++++>",element)

  const gqlQuery = GQL_QUERIES.GET_COMPANIES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            element: element,
          },
          type: gqlQuery.input,
        },
      },
      fields: gqlQuery.fields,
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);

  MOUNTED_COMPANIES.value = response.data?.[gqlQuery.operation] || {};
  // console.log("MOUNTED_COMPANIES ==>", MOUNTED_COMPANIES.value)
  inProgress.value = false;

};

export const ALL_COMPANIES_OPTS = computed(() => {
  return (MOUNTED_COMPANIES.value || []).map((nth) => {
    return {
      label: nth.name,
      value: nth.id,
    };
  });
});

export const mountProfile = async (
  router,
  { profile, element, uuid = null } = {}
) => {
  MOUNTED_COMPANY_PROFILE.value = profile;
  router.push({
    name: ROUTES.COMPANY_PROFILE.name,
    params: { id: profile?.uuid || uuid },
    query: { element },
  });
};
