<template>
  <div class="col-12" style="height: 700px">
    <h3 class="text-center p-3">
      Proxima - asset level coverage via Project Blue’s interactive supply chain
      analytics platform
    </h3>
    <div class="floating-menu">
      <div class="row gy-2">
        <div class="col-12">
          <SelectElement />
        </div>
        <div class="col-12">
          <SummaryCard
            :title="$t('ProximaList.assetCard.title')"
            :profiles="PROFILE_MARKS"
            :count="PROFILE_MARKS.length"
            :error="$t('ProximaList.assetCard.notFound')"
            :hideDropdown="true"
          >
            <div
              class="text-center shadow icon icon-shape bg-gradient-primary rounded-circle"
            >
              <i class="text-lg opacity-10 ni ni-money-coins"></i>
            </div>
          </SummaryCard>
        </div>
        <div class="col-12">
          <div class="card">
            <div class="p-3 card-body">
              <div class="block font-weight-bold">Filter assets by</div>

              <div class="block">
                <div class="mapbox-legend mapbox-legend--white"></div>
                <span
                  style="cursor: pointer"
                  @click="
                    updateMapMarkers();
                    legendMenu = null;
                  "
                  >View All</span
                >
              </div>

              <div class="block" v-if="!legendMenu">
                <div class="mapbox-legend mapbox-legend--white"></div>
                <span style="cursor: pointer" @click="showTypes">Type</span>
              </div>
              <div class="block" v-if="!legendMenu">
                <div class="mapbox-legend mapbox-legend--white"></div>
                <span style="cursor: pointer" @click="showStatus">Status</span>
              </div>

              <div class="block" v-if="legendMenu === 'TYPE'">
                <div class="mapbox-legend mapbox-legend--red"></div>
                <span
                  style="cursor: pointer"
                  @click="showMineAndBeneficiation()"
                  >Mine & Beneficiation</span
                >
              </div>
              <div class="block" v-if="legendMenu === 'TYPE'">
                <div class="mapbox-legend mapbox-legend--blue"></div>
                <span style="cursor: pointer" @click="showRefineryAndFactory()"
                  >Refinery & Factory</span
                >
              </div>

              <div class="block" v-if="legendMenu === 'STATUS'">
                <div class="mapbox-legend mapbox-legend--red"></div>
                <span style="cursor: pointer" @click="showClosed()"
                  >Closed/C&M</span
                >
              </div>
              <div class="block" v-if="legendMenu === 'STATUS'">
                <div class="mapbox-legend mapbox-legend--blue"></div>
                <span style="cursor: pointer" @click="showProject()"
                  >Project</span
                >
              </div>
              <div class="block" v-if="legendMenu === 'STATUS'">
                <div class="mapbox-legend mapbox-legend--green"></div>
                <span style="cursor: pointer" @click="showOperating()"
                  >Operating</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <iframe
      id="interactiveMap"
      :src="envs('HOME_MAP') + '?version=111'"
      frameborder="0"
      style="width: 100%; height: 100%"
    ></iframe>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, nextTick } from "vue";
import { envs } from "../../utils";
import SelectElement from "../../pages/Home/SelectElement.vue";
import SummaryCard from "../dumb/SummaryCard.vue";
import { PROFILE_MARKS, getMapData } from "../../composables/maps";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
let legendMenu = ref(null);

const updateMapMarkers = (type_or_status, groups) => {
  let marks = [];
  for (const nth of PROFILE_MARKS.value || []) {
    let group = null;
    if (type_or_status === "TYPE") group = nth.type;
    else if (type_or_status === "STATUS") group = nth.status;
    marks.push({
      type: "Feature",
      properties: {
        group,
      },
      geometry: {
        type: "Point",
        coordinates: [...nth.coordinates],
      },
    });
    if (nth.coordinates?.length !== 2) console.log(nth.coordinates);
  }

  if (type_or_status)
    marks = marks.filter((nth) => groups.includes(nth.properties.group));

  nextTick(() => {
    const map = document.getElementById("interactiveMap");
    if (map) {
      console.log("sending: ", marks.length, groups);
      map.contentWindow.postMessage({ marks }, "*");
      map.contentWindow.console = console;
    }
  });
};

onMounted(async () => {
  getMapData();
});

const showTypes = () => {
  legendMenu.value = "TYPE";
  updateMapMarkers("TYPE", [
    "Mine",
    "Recycler",
    "Recycling",
    "Tailings",
    "Tailings/Slag",
    "Factory",
    "Refinery",
  ]);
};

const showStatus = () => {
  legendMenu.value = "STATUS";
  updateMapMarkers("STATUS", [
    "Closed",
    "Idle",
    "Unknown",
    "Project",
    "Operating",
  ]);
};

const showMineAndBeneficiation = () => {
  updateMapMarkers("TYPE", [
    "Mine",
    "Recycler",
    "Recycling",
    "Tailings",
    "Tailings/Slag",
  ]);
};

const showRefineryAndFactory = () => {
  updateMapMarkers("TYPE", ["Factory", "Refinery"]);
};

const showClosed = () => {
  updateMapMarkers("STATUS", ["Closed", "Idle", "Unknown"]);
};

const showProject = () => {
  updateMapMarkers("STATUS", ["Project"]);
};

const showOperating = () => {
  updateMapMarkers("STATUS", ["Operating"]);
};

const FRAME_HAS_LOADED = ref(false);
watch(PROFILE_MARKS, async () => {
  if (FRAME_HAS_LOADED.value) updateMapMarkers();
});
window.addEventListener("message", (event) => {
  console.log(event.data);
  if (event.data === "FRAME_LOADED") {
    console.log("send marker data");
    updateMapMarkers();
    FRAME_HAS_LOADED.value = true;
  }
});
</script>

<style lang="scss" scoped>
.floating-menu {
  width: 250px;
  position: absolute;
  margin: 10px 0 0 20px;
}

.mapbox-legend {
  margin-right: 10px;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  display: inline-block;

  &--red {
    background-color: #d83615;
  }

  &--blue {
    background-color: #293c4f;
  }

  &--green {
    background-color: #1c9099;
  }

  &--white {
    background-color: #fff;
    border: 1px solid black;
  }
}
</style>
