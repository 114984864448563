import { getAssetProfile } from "../composables/profiles/asset";
import { getPublicCountries } from "../composables/profiles/country";

export const usage = { usage: ["uuid", "type"] };
export const profiles = { profiles: [
  "accessControls",
  "alternativeNames",
  "assignedTo",
  "attachmentLinks",
  "byProductCommodities",
  "changes",
  "commissioned",
  "commodities",
  "coordinates",
  "costed",
  "country",
  "countryName",
  "created",
  "createdBy",
  "editedBy",
  "feedStock",
  "geologicalRegion",
  "id",
  "installationType",
  "installationYear",
  "lom",
  "modified",
  "name",
  "nameShort",
  "nearestTown",
  "nonCommercialCommodities",
  "operatorId",
  "operatorName",
  "pinAccuracy",
  "profile",
  "projects",
  "province",
  "reservesNotes",
  "reservesTotalTons",
  "reservesTotalTonsUnit",
  "resourcesNotes",
  "resourcesTotalTons",
  "resourcesTotalTonsUnit",
  "sourceLinks",
  "stage",
  "status",
  "streamType",
  "totalTonsIndicated",
  "totalTonsInferred",
  "totalTonsMeasured",
  "totalTonsProbable",
  "totalTonsProven",
  "totalTonsReserves",
  "totalTonsResources",
  "type",
  "uuid",
  "versions",
  "OEMSuppliers",
] }
export const profiles2 = { profiles: [
  "associatedCompanies",
  "changes",
  "company",
  "companyName",
  "companyOverview",
  "coordinates",
  "created",
  "createdBy",
  "editedBy",
  "headquarterCountry",
  "headquarterCountryName",
  "miningCommodities",
  "modified",
  "ownerType",
  "ownershipImage",
  "pinAccuracy",
  "products",
  "refiningProducts",
  "uuid",
  "versions",
  "website",
  { commodityOverview: ["commodity", "links", "overview"] },
  { stockExchanges: [ "code", "stockExchange", "stocks",] },
  { ownership: ["id", "percentage"] }
] }

export const getCountryProfilesFields = {
  profiles: [
    "attachmentLinks",
    "changes",
    "commodity",
    "country",
    "countryName",
    "created",
    "editedBy",
    "geology",
    "modified",
    "other",
    "ppd",
    "production",
    "sourceLinks",
    "uuid",
    "versions",
  ]
}

export const getAssetProfileFields = [
  "OEMSuppliers",
  "accessControls",
  "alternativeNames",
  "assignedTo",
  "attachmentLinks",
  "byProductCommodities",
  "changes",
  "commissioned",
  "commodities",
  "coordinates",
  "costed",
  "country",
  "countryName",
  "created",
  "createdBy",
  "editedBy",
  "feedStock",
  "geologicalRegion",
  "id",
  "installationType",
  "installationYear",
  "lom",
  "modified",
  "name",
  "nameShort",
  "nearestTown",
  "nonCommercialCommodities",
  "operatorId",
  "operatorName",
  "pinAccuracy",
  "profile",
  "projects",
  "province",
  "reservesNotes",
  "reservesTotalTons",
  "reservesTotalTonsUnit",
  "resourcesNotes",
  "resourcesTotalTons",
  "resourcesTotalTonsUnit",
  "sourceLinks",
  "stage",
  "status",
  "streamType",
  "totalTonsIndicated",
  "totalTonsInferred",
  "totalTonsMeasured",
  "totalTonsProbable",
  "totalTonsProven",
  "totalTonsReserves",
  "totalTonsResources",
  "type",
  "uuid",
  "versions"
]

export const getPublicSupplyDataFields = [
  "assetProfile",
  "assetProfileName",
  "company",
  "companyName",
  "country",
  "countryName",
  "element",
  "elementName",
  "id",
  "product",
  "productName",
  "stage",
  "status",
  "type",
  "units",
  {yearlyData: ["value", "year"]}
]

export const getPublicCountriesFields = ["code", "name", { publishDetails: ["element", "uuid"] }, "region"]

export const publishDetails = { publishDetails: ["elements","uuid"] }

export const getGenCoutryProfFields = [
  "banner",
  "countryCode",
  "countryName",
  "id",
  "overview",
  "policies",
  "uuid"
]

export const GQL_QUERIES = {
  GET_SUBSCRIPTION_DETAILS: {
    operation: "getSubscriptionDetails",
  },
  GET_SUBSCRIBED_ASSET_PROFILES: {
    operation: "getSubscribedProfiles",
    name: "GetPublishedProfilesInput",
  },
  GET_SUBSCRIBED_ASSET_PROFILE: {
    operation: "getSubscribedAssetProfile",
    input: "GetPublishedAssetProfileInput",
  },
  GET_SUBSCRIBED_COMPANY_PROFILE: {
    operation: "getSubscribedCompanyProfile",
    input: "GetPublishedCompanyProfileInput",
  },
  GET_SUBSCRIBED_COUNTRY_PROFILE: {
    operation: "getSubscribedCountryProfile",
    input: "GetPublishedCountryProfileInput",
  },
  GET_PUBLIC_COUNTRIES: {
    operation: "getPublicCountries",
    input: "GetPublicDataByElementInput",
    fields: getPublicCountriesFields
  },
  GET_USER_NOTES: {
    operation: "getUserNotes",
    input: "GetUserNotesInput",
  },
  GET_USER_NOTE: {
    operation: "getUserNote",
  },
  GET_USER_FILES: {
    operation: "getSubscribedFiles",
  },
  GET_STREAMS: {
    operation: "getSubscribedStreams",
  },
  GET_PUBLIC_ARTICLES: {
    // operation: "getPublicArticles",
    operation: "getAllPublicArticles",
  },
  GET_COMMODITIES: {
    operation: "getCommodities",
  },
  GET_WORLD_TRADE_DATA: {
    operation: "getCleanWorldTradeData",
    input: "GetWorldTradeDataInput",
  },
  GET_NOTIFICATIONS: {
    operation: "getProfilesNotifications",
    fields: [
      "id",
      "type",
      "key",
      "name",
      "seen",
      "event",
      "commodities",
      "created",
    ],
  },
  GET_ALL_FILES: {
    operation: "getAllFiles",
    fields: [],
  },
  GET_MAP_DATA: {
    operation: "getMappedAssetProfiles",
    input: "GetMappedAssetProfilesInput",
    fields: [
      { marks: ["id", "type", "status", "coordinates"] },
      { elements: ["code", "name"] },
    ],
  },
  GET_COMPANIES: {
    operation: "getPublicCompanies",
    input: "GetPublicDataByElementInput",
    fields: ["id", "name", "hasProfile", publishDetails, usage],
  },
  GET_COUNTRIES: {
    operation: "getPublicCountries",
    input: "GetPublicDataByElementInput",
    fields: ["code", "name", "region", publishDetails, usage],
  },



  GET_ASSET_PROFILES: {
    operation: "getAssetProfiles",
    input: "GetAssetProfilesInput",
    fields: ["count", profiles],
  },
  GET_COMPANY_PROFILES: {
    operation: "getCompanyProfiles",
    input: "GetCompanyProfilesInput",
    fields: [
      "count",
      profiles2
    ]
  },
  GET_COUNTRY_PROFILES: {
    operation: "getCountryProfiles",
    input: "GetCountryProfilesInput",
    fields : [ "count", getCountryProfilesFields ]
  },
  GET_ASSET_PROFILE: {
    operation: "getAssetProfile",
    fields : [ getAssetProfileFields ]
  },

  GET_PUBLIC_SUPPLY_DATA: {
    operation: "getPublicSupplyData",
    input: "GetPublicSupplyDataInput",
    fields: getPublicSupplyDataFields
  },
  GET_GENERAL_COUNTRY_PROFILE: {
    operation: "getSubscribedGeneralCountryProfile",
    input: "GetPublishedCountryProfileInput",
    fields: getGenCoutryProfFields
  }

  
};

export const GQL_MUTATION = {
  MANAGE_PERSONAL_NOTE: {
    operation: "manageUserNote",
    input: "ManageUserNoteInput",
  },
  DELETE_PERSONAL_NOTE: {
    operation: "deleteUserNote",
    input: "DeleteUserNoteInput",
  },
  OPEN_NOTIFICATION: {
    operation: "openNotification",
  },
  DOWNLOAD_FILE: {
    operation: "downloadSubscribedFile",
    input: "DownloadSubscribedFileInput",
  },
  INQUIRE_TO_MARKETING: {
    operation: "inquireToMarketing",
    input: "InquireToMarketingInput",
  },
};

export const AUTH = {
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  VALIDATE_TOKEN: "/auth/verify",
  REFRESH_TOKEN: "/auth/refresh",
  LOGOUT: "/auth/logout",
  RESET_PASSWORD: "/auth/reset-password",
};
