<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="card shadow-lg w-xxl-100">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div
                class="avatar avatar-lg bg-gradient-dark border-radius-md position-relative"
              >
                <i class="ni ni-folder-17 text-white text-2xl"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-0">{{ $t("Files.costServiceTitle") }}</h5>
                <p class="mb-0 font-weight-bold text-xs">
                  {{ $t("Files.secondaryTitle") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div v-if="inProgress" class="col-12 mt-1 d-flex justify-content-center">
        <NSpace>
          <NSpin size="medium" class="custom-spin-color" />
        </NSpace>
      </div>
      <div v-else class="row g-2">
        <div
          class="col-md-6 col-xs-12"
          v-for="nth in sortedFolders || []"
          :key="nth"
        >
          {{ sortedFolders.name }}

          <div class="custom-card card shadow-lg w-xxl-100">
            <div class="card-body p-3">
              <div class="container p-0">
                <div class="row g-0">
                  <div
                    class="col-md-3 col-xs-12 col-xxl-3 position-relative p-0"
                  >
                    <img
                      :src="nth.thumbnail"
                      class="img-fluid h-100 rounded cover-img"
                      alt="..."
                    />
                  </div>

                  <div
                    class="col-md-9 col-xs-12 col-xxl-9 position-relative p-0"
                  >
                    <div class="col-12">
                      <h5 class="card-title p-0">
                        <div class="d-flex d-flex-row">
                          <div
                            class="p-2 avatar avatar-sm bg-gradient-dark border-radius-md"
                          >
                            <i v-if="nth.name.toUpperCase().replaceAll(' ', '_') === CRITICAL_MATERIALS_NAME" class="ni ni-folder-17 text-white text-xl"></i>
                            <span v-else class="text-md text-bold text-white">
                              {{
                                nth.element === "Graphite" || nth.element === "SALT" ? $t(`MarketServices.elements.${nth.name.toLowerCase()}`) : nth.element
                              }}
                            </span>
                          </div>
                          <span
                            style="margin-left: 5px"
                            class="p-0 cursor-pointer font-weight-bold text-lg"
                          >
                            <h4>
                              {{nth.name}}
                            </h4>
                          </span>
                        </div>
                      </h5>
                    </div>

                    <div>
                      <CostServicesBtns :sortedFolders="sortedFolders.filter(r => r.element === nth.element)" @viewFiles="viewFiles" />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NDrawer
      v-model:show="showDrawer"
      @update:show="clearProp()"
      :width="502"
      placement="right"
    >
      <NDrawerContent>
        <div class="row">
          <div class="col-12">
            <div class="card mb-4">
              <div class="card-header pb-0">
                <h6 class="text-capitalize" v-if="showCritMatList">
                  {{ $t(`Files.seriesNames.CRITICAL_MATERIALS`) }}
                </h6>
                <h6 class="text-capitalize" v-if="showMarketSupportForm">
                  {{ $t(`Files.seriesNames.MARKET_SUPPORT`) }}
                </h6>
                <h6 class="text-capitalize" v-if="showFiles">
                  <span v-if="isMonthlyService">{{
                    $t(`Files.seriesNames.series4Title`)
                  }}</span>
                  <span v-else> {{
                    $t(`Files.seriesNames.seriesTitle`, {
                      series: selectedSeries,
                    })
                  }}
                  </span>
                </h6>
              </div>
              <div class="card-body px-0 pt-0" v-if="showCritMatList">
                <NDataTable :columns="columns" :data="criticalMaterialsData" />
              </div>
              <div class="card-body pt-1" v-if="showMarketSupportForm">
                <MarketSupportForm
                  :elementProp="elementArrayProp"
                  :userEmail="userEmail"
                  @customEvent="handleCustomEvent"
                >
                </MarketSupportForm>
              </div>
              <div class="card-body px-0 pt-0" v-if="showFiles">
                <NDataTable :columns="columns" :data="data" />
              </div>
            </div>
          </div>
        </div>
      </NDrawerContent>
    </NDrawer>
  </main>
</template>

<script setup>
import { sortBy, forEach } from "lodash";
import jwtDecode from "jwt-decode";
import { onMounted, ref, computed, h,  } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  NDrawer,
  NDrawerContent,
  NDataTable,
  NButton,
  NPopover,
  NSpin,
  NSpace
} from "naive-ui";
import { ROUTES } from "../../routes/names";
import {
  USER_FILES,
  getUserFiles,
  MARKET_SERVICES2,
  COST_SERVICES,
  MONTHLY_SERVICES,
  CRITICAL_MATERIALS_NAME,
  inProgress,
  MARKET_SERVICE_FILE,
  COST_SERVICE_FILE,
  DATA_SERIES_TYPE
} from "../../composables/files/list";
import TableCell from "../../components/FilesTable/TableCell";
import TableCellDownload from "../../components/FilesTable/TableCellDownload";
import TableHeader from "../../components/FilesTable/TableHeader";
import { useI18n } from "vue-i18n";
import SeriesNames from "../../utils/series.json";
import MarketSupportForm from "../../components/Drawer/MarketSupport";

import { MARKET_SERVICES } from "../../composables/generics";
import DefaultServicesBtns from "../../components/FilesButtons/DefaultServicesBtns";
import MonthlyServicesBtns from "../../components/FilesButtons/MonthlyServicesBtns";
import CostServicesBtns from "../../components/FilesButtons/CostServicesBtns";
import CriticalMaterialsBtns from "../../components/FilesButtons/CriticalMaterialsBtns";

const router = useRouter();
const route = useRoute();

const { t } = useI18n();
const showDrawer = ref(false);
const showCritMatList = ref(false);
const showMarketSupportForm = ref(false);
const showFiles = ref(false);
const selectedSeries = ref(null);
let selectedElement = ref(null);
let elementArrayProp = ref([]);
const FOLDERS = ref([]);
const criticalMaterialsData = ref([]);
const sortedFolders = ref([]);
const isMonthlyService = ref(null);

const viewFiles = (fileData) => {
  showDrawer.value = !showDrawer.value;

  if(fileData.element.toUpperCase().replaceAll(' ', '_') !== CRITICAL_MATERIALS_NAME){
    if (fileData.series === "PROXIMA") {
      router.push({
        name: ROUTES.PROXIMA_PROFILES.name,
        query: { element: fileData.element },
      });
    } else if (fileData.series === "MARKET_SUPPORT") {
      elementArrayProp.value.push(fileData.name);
      showMarketSupportForm.value = true;
      showFiles.value = false;
      showCritMatList.value = false;
    } else {
      if(MONTHLY_SERVICES.value.includes(fileData.element)){
        isMonthlyService.value = true;
      }else{
        isMonthlyService.value = false;
      }
      selectedElement = fileData.element;
      showFiles.value = true;
      showMarketSupportForm.value = false;
      showCritMatList.value = false;

      selectedSeries.value = SeriesNames.series
        .filter((r) => r.code === fileData.series)
        .map((r) => r.name)[0];

      const filesInElements =
        (USER_FILES.value?.files || []).find((nth) => nth.element === fileData.element)
          .files || [];
      filesInSeries.value = filesInElements.filter(
        (file) => file.fileSeries === fileData.series
      );
    }
  }else{
    if (fileData.series === "MARKET_SUPPORT") {
      elementArrayProp.value.push(fileData.name);
      showMarketSupportForm.value = true;
      showFiles.value = false;
      showCritMatList.value = false;
    }else{
      showCritMatList.value = true;
      showMarketSupportForm.value = false;
      showFiles.value = false;
      criticalMaterialsData.value = fileData.files.map((nth) => {
        return {
          title: nth.displayName,
          type: nth.fileType,
          url: nth.fileUrl,
          id: nth.id,
        };
      });
    }
  }
};

const userEmail = computed(() => {
  const jwtToken = localStorage.getItem("access");

  if (jwtToken) {
    var decoded = jwtDecode(jwtToken);
    return decoded.email;
  }
});

const clearProp = (newValue) => {
  if (!newValue) {
    if (route.query?.element) {
      router.push({ name: ROUTES.COST_ANALYSIS.name });
    }
    elementArrayProp.value = [];
  }
};

// TODO: need to refactor this and make a single component for files list
const filesInSeries = ref([]);
const columns = computed(() => {
  return [
    {
      key: "name",
      title(column) {
        return h(TableHeader, {
          text: t("Files.fileHeader"),
        });
      },
      render: (row) => {
        return h(TableCell, {
          text: row.title,
          url: row.url,
          type: row.type,
        });
      },
    },
    {
      key: "action",
      title: null,
      render: (row) => {
        return h(TableCellDownload, {
          selectedElement: selectedElement,
          text: row.title,
          url: row.url,
          id: row.id,
        });
      },
    },
  ];
});

const data = computed(() => {
  const files = filesInSeries.value || [];
  return files.map((nth) => {
    let displayName = "";
    if (nth.displayName.includes("101:")) {
      displayName = nth.displayName.replace(/101/, selectedSeries.value);
    } else if (nth.displayName.includes("102:")) {
      displayName = nth.displayName.replace(/102/, selectedSeries.value);
    } else if (nth.displayName.includes("103:")) {
      displayName = nth.displayName.replace(/103/, selectedSeries.value);
    } else if (nth.displayName.includes("201:")) {
      displayName = nth.displayName.replace(/201/, selectedSeries.value);
    } else if (nth.displayName.includes("202:")) {
      displayName = nth.displayName.replace(/202/, selectedSeries.value);
    } else if (nth.displayName.includes("203:")) {
      displayName = nth.displayName.replace(/203/, selectedSeries.value);
    } else {
      displayName = nth.displayName;
    }

    return {
      title: displayName,
      type: nth.fileType,
      url: nth.fileUrl,
      id: nth.id,
    };
  });
});

onMounted(async () => {
  if (!USER_FILES.value?.files) await getUserFiles();

  FOLDERS.value = MARKET_SERVICES2.value?.filter(r => r.isPublic === true && r.serviceType === COST_SERVICE_FILE)
  .map(service => {
    const fileMatch = USER_FILES.value?.files.find(file => file.element === service.element);

    return {
      ...service,
      files: fileMatch ? fileMatch.files : [],
    };
  });

  const foldersWithFiles = FOLDERS.value?.filter(folder => folder.files.length > 0 
    && folder.element.toUpperCase().replaceAll(' ', '_') !== CRITICAL_MATERIALS_NAME)
    .sort((a, b) => a.name.localeCompare(b.name));

  const otherFolders = FOLDERS.value?.filter(folder => folder.files.length === 0 
    && folder.element.toUpperCase().replaceAll(' ', '_') !== CRITICAL_MATERIALS_NAME)
    .sort((a, b) => a.name.localeCompare(b.name));

  sortedFolders.value = [...foldersWithFiles, ...otherFolders].filter(Boolean)

  if (route.query.element) {
    let result = null;

    result = sortedFolders.value.filter(r => r.element === route.query?.element).map(r => {
      let fileData = {
        series: DATA_SERIES_TYPE,
        name: r.name,
        element: r.element,
        files: r.files
      }
      return fileData;
    })
    await viewFiles(result[0]);
  }
});
</script>

<style lang="scss" scope>
.n-input-group {
  display: inline-flex;
  width: 100%;
  flex-wrap: nowrap;
  vertical-align: bottom;
}
.proxima-blue-banner-image {
  background-image: url("/images/Critical_Materials_Banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: center;
}
.proxima-blue-banner-image:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, #192e40, #000);
  opacity: 0.5;
}
.pdf-btn {
  background-color: #f1ae31;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 0.75rem;
  height: 48px;
  width: 48px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #192e40;
  }
}
.xls-btn {
  background-color: green;

  .cover-img {
    width: 100%;
    height: 50%; /* Adjust image size */
  }

  .custom-card {
    margin-bottom: 20px;
  }

  .custom-btn {
    width: 100%;
  }

  .custom-btn-txt {
    font-size: 12px; /* Adjust font size */
  }

  .custom-btn2 {
    width: 100%;
  }

  .custom-btn-txt2 {
    font-size: 12px; /* Adjust font size */
  }

  .file-icon {
    width: 20px; /* Adjust icon size */
  }
}

// Medium devices (tablets, 768px and up)
@media (width >= 768px) {
  .box {
    background-color: yellow;
  }

  .custom-btn {
    width: 31%;
    font-size: 5px;
  }
  .custom-btn-txt {
    font-size: 10px;
  }

  .custom-btn2 {
    width: 31%;
    font-size: 3px;
  }
  .custom-btn-txt2 {
    font-size: 10px;
  }

  .file-icon {
    width: 10px;
  }
}

// Large devices (desktops, 992px and up)
@media (width >= 992px) {
  .box {
    background-color: blue;
  }

  .custom-btn {
    width: 32%;
    font-size: 5px;
  }

  .custom-btn2 {
    width: 32%;
    font-size: 3px;
  }

  .file-icon {
    width: 15px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (width >= 1200px) {
  .box {
    background-color: pink;
  }
  .custom-btn {
    width: 32%;
    font-size: 5px;
  }

  .custom-btn2 {
    width: 32%;
    font-size: 3px;
  }

  .file-icon {
    width: 20px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (width >= 1400px) {
  .box {
    background-color: black;
  }

  .cover-img {
    height: 100px;
  }

  .custom-btn {
    width: 32%;
  }

  .custom-btn-txt {
    font-size: 15px;
  }

  .custom-btn2 {
    width: 32%;
  }
}
</style>
