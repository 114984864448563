import { reactive, ref, computed } from "vue";
import { query } from "gql-query-builder";
import { sortBy } from "lodash";
import { gqlRequest } from "../request";
import { GQL_QUERIES } from "../../utils/APIs";
import { fields as assetProfileFields } from "./asset";
import { fields as companyProfileFields } from "./company";
import { fields as countryProfileFields } from "./country";
import { fields as dealsAndContractProfileFields } from "./dealsAndContracts";

export const PROFILES_FILTERS = reactive({
  assets: [],
  countries: [],
  companies: [],
  types: [],
  status: [],
  stages: [],
  productGroups: [],
  products: [],
});
export const PUBLISHED_PROFILES = ref({});
export const PUBLISHED_ALL_PROFILES = ref({});
export const inProgress = ref(false);
export const reqSignal = ref(new AbortController());

export const ASSETS_OPTS = computed(() => {
  const assets = [
    ...new Map(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => [
        nth.uuid,
        nth,
      ])
    ).values(),
  ];
  const opts = assets.map((nth) => {
    return {
      label: nth.shortName || nth.name,
      value: nth.uuid,
    };
  });
  return sortBy(opts, "label");
});
export const COUNTRIES_OPTS = computed(() => {
  const countries = [
    ...new Map(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => [
        nth.countryCode,
        nth,
      ])
    ).values(),
  ];
  const opts = countries
    .filter((nth) => nth.countryCode)
    .map((nth) => {
      return {
        label: nth.countryName,
        value: nth.countryCode,
      };
    });
  return sortBy(opts, "label");
});
export const COMPANIES_OPTS = computed(() => {
  const companies = [
    ...new Map(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => [
        nth.companyId,
        nth,
      ])
    ).values(),
  ];
  const opts = companies
    .filter((nth) => nth.companyId)
    .map((nth) => {
      return {
        label: nth.companyName,
        value: nth.companyId,
      };
    });
  return sortBy(opts, "label");
});
export const TYPES_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => nth.type)
    ),
  ];
  const opts = tmp
    .filter((type) => type?.length > 0)
    .map((type) => {
      return {
        label: type,
        value: type,
      };
    });
  return sortBy(opts, "label");
});
export const STATUS_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => nth.status)
    ),
  ];
  const opts = tmp
    .filter((status) => status?.length > 0)
    .map((status) => {
      return {
        label: status,
        value: status,
      };
    });
  return sortBy(opts, "label");
});
export const STAGES_OPTS = computed(() => {
  const tmp = [
    ...new Set(
      (PUBLISHED_PROFILES.value?.assetProfiles || []).map((nth) => nth.stage)
    ),
  ];
  const opts = tmp
    .filter((stage) => stage?.length > 0)
    .map((stage) => {
      return {
        label: stage,
        value: stage,
      };
    });
  return sortBy(opts, "label");
});
export const PRODUCT_OPTS = computed(() => {
  const tmp = {};
  for (const profile of PUBLISHED_PROFILES.value?.assetProfiles || []) {
    for (const prod of profile?.products || []) {
      tmp[prod.productId] = {
        label: prod.name,
        value: prod.productId,
      };
    }
  }
  const opts = Object.values(tmp);
  return sortBy(opts, "label");
});

export const getPublishedProfiles = async (element) => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_ASSET_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            element: element,
            assets: PROFILES_FILTERS.assets,
            countries: PROFILES_FILTERS.countries,
            companies: PROFILES_FILTERS.companies,
            types: PROFILES_FILTERS.types,
            status: PROFILES_FILTERS.status,
            stages: PROFILES_FILTERS.stages,
            productGroups: PROFILES_FILTERS.productGroups,
            products: PROFILES_FILTERS.products,
          },
          type: gqlQuery.name,
          required: true,
        },
      },
      fields: [
        { assetProfiles: assetProfileFields },
        { companyProfiles: companyProfileFields },
        { countryProfiles: countryProfileFields },
        { dealAndContractProfiles: dealsAndContractProfileFields },
        "element",
        "total",
        "count",
      ],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  inProgress.value = false;
  PUBLISHED_PROFILES.value = response.data?.[gqlQuery.operation] || {};
  console.log("PAYLOAD PUBLISHED_PROFILES==>", payload)
  console.log("PUBLISHED_PROFILES ===>", PUBLISHED_PROFILES.value)
};

export const getAllPublishedProfiles = async (element) => {
  const gqlQuery = GQL_QUERIES.GET_SUBSCRIBED_ASSET_PROFILES;
  reqSignal.value.abort();
  reqSignal.value = new AbortController();
  inProgress.value = true;
  const payload = query([
    {
      operation: gqlQuery.operation,
      variables: {
        input: {
          value: {
            element: element,
            assets: PROFILES_FILTERS.assets,
            countries: PROFILES_FILTERS.countries,
            companies: PROFILES_FILTERS.companies,
            types: PROFILES_FILTERS.types,
            status: PROFILES_FILTERS.status,
            stages: PROFILES_FILTERS.stages,
            productGroups: PROFILES_FILTERS.productGroups,
            products: PROFILES_FILTERS.products,
          },
          type: gqlQuery.name,
          required: true,
        },
      },
      fields: [
        { assetProfiles: assetProfileFields },
        { companyProfiles: companyProfileFields },
        { countryProfiles: countryProfileFields },
        { dealAndContractProfiles: dealsAndContractProfileFields },
        "element",
        "total",
        "count",
      ],
    },
  ]);
  const response = await gqlRequest(payload, reqSignal.value.signal);
  PUBLISHED_ALL_PROFILES.value = response.data?.[gqlQuery.operation] || {};
  console.log("PAYLOAD ALL PUBLISHED_PROFILES==>", payload)
  console.log("PUBLISHED_ALL_PROFILES ===>", PUBLISHED_ALL_PROFILES.value)
  inProgress.value = false;

};
