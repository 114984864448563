<template>
  <!-- <div class="align-items-center"><img src="/images/proximaHeaderImage.png" alt=""></div> -->
    <a
      href="javascript:;"
      class="p-0 nav-link text-white"
      :class="[NAVBAR_SHOW_NAVITEMS_MENU ? 'show' : '']"
      id="navItemsMenuButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="NAVBAR_SHOW_NAVITEMS_MENU = !NAVBAR_SHOW_NAVITEMS_MENU"
    >
      <i class="cursor-pointer fa fa-solid fa-bars fa-lg"></i>
    </a>
    <ul
      class="dropdown-menu dropdown-menu-end me-sm-n4 me-md-n3 p-2"
      ref="popupWindow"
      :class="
        NAVBAR_SHOW_NAVITEMS_MENU ? 'show' : ''
      "
      aria-labelledby="navItemsMenuButton"
    >
      <div style="max-height: 500px; overflow-y: auto">
        <li class="nav-item   proxima-secondary">
            <SidenavItem
            :url="ROUTES.DASHBOARD.name"
            >
            <template v-slot:text>
                <i class="ni ni-chart-bar-32 text-sm" style="margin-right: 23%;"></i>
                {{ $t("App.sidebarMenu.dashboardPage") }}
            </template>
            </SidenavItem>
        </li>
        <li class="nav-item   proxima-secondary">
            <SidenavItem
            :url="ROUTES.PROXIMA_PROFILES.name"
            >
            <template v-slot:text>
                <i class="ni ni-pin-3 text-sm" style="margin-right: 20%;"></i>
                {{ $t("App.sidebarMenu.proximaPage") }}</template
            >
            </SidenavItem>
        </li>
        <li class="nav-item">
            <SidenavItem
            :url="ROUTES.FILES.name"
            >
            <template v-slot:text>
                <i class="ni ni-folder-17 text-sm" style="margin-right: 10%;"></i>
                {{ $t("App.sidebarMenu.filesPage") }}
            </template>
            </SidenavItem>
        </li>
      </div>
    </ul>
  </template>
  
  <script setup>
  import { ref } from "vue";
  import { onClickOutside } from "@vueuse/core";
  import { NAVBAR_SHOW_NAVITEMS_MENU } from "../../composables/generics";
  import { ROUTES } from "../../routes/names";
  import SidenavItem from "../Sidenav/SidenavItem.vue";
  const popupWindow = ref(null);
  
  onClickOutside(
    popupWindow,
    (event) => (NAVBAR_SHOW_NAVITEMS_MENU.value = false)
  );
  </script>
  